import React from "react";
import StoreCart from "./StoreCart";
import {
  IonHeader,
  IonPage,
  IonContent,
  IonToolbar,
  IonTitle,
  IonLoading
} from "@ionic/react";
import { useShoppingCartCopy } from "../services/hooks/useShoppingCart";
import { useParams } from "react-router";

export default function CartCopy() {
  const params: any = useParams();
  const [cart, state] = useShoppingCartCopy(params && params.id);
  if (!cart) {
    return <IonLoading isOpen={true} message="Recuperando Carrito" />;
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>Carrito</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StoreCart cart={cart && cart.products} />
      </IonContent>
    </IonPage>
  );
}
