import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isPlatform, IonAlert, IonLoading, IonToast } from '@ionic/react';
import { setError, setNotification } from './pages/MarketPlace/redux/cartReducer';

export default function AppMessage() {
  const notification = useSelector(({ cart }: any) => {
    return cart.notification;
  });
  const error = useSelector(({ cart }: any) => {
    return cart.error;
  });
  const loading = useSelector(({ cart }: any) => {
    return cart.loading;
  });
  const message = useSelector(({ cart }: any) => {
    return cart.message;
  });
  const dispatch = useDispatch();
  return (
    <>
      <IonToast
        position={isPlatform('desktop') ? 'top' : 'bottom'}
        cssClass="notification-message"
        duration={7000}
        color="warning"
        isOpen={!!notification}
        message={notification}
        onDidDismiss={() => {
          dispatch(setNotification(''));
        }}
      ></IonToast>
      <IonAlert isOpen={!!error} onDidDismiss={() => dispatch(setError(error))} message={error && error.message} />
      <IonLoading spinner="bubbles" isOpen={loading} message={message} />
    </>
  );
}
