import React, { useRef } from 'react';
import { IonItem, IonInput, IonLabel, IonIcon } from '@ionic/react';
import WarnIcon from './WarnIcon';
import bolivia from '../../assets/images/bolivia.svg';
import CountryPhone from './CountryPhone';

const ElevatedPhone = ({
  type,
  onChange,
  onInput,
  onBlur,
  onRemove,
  value = '',
  label,
  id,
  meta,
  loading,
  ...params
}: any) => {
  let inputRef = useRef<any>();
  const error = meta && meta.error;
  const focusInput = () => {
    if (inputRef && inputRef.current !== null) {
      inputRef.current.setFocus();
    }
  };
  const parts = (value || '').split('-');
  const val = parts[parts.length - 1];
  const area = parts.length < 2 ? 'BO' : parts[0];
  const onChangePhone = (code: any, phone: any) => {
    const cellphone = `${code}-${phone}`;
    onChange({ detail: { value: cellphone } });
  };
  return (
    <div className="elevated-box">
      <div className="elevated-container">
        <IonItem className={`elevated-item ${error ? 'ion-invalid' : ''}`} onClick={focusInput}>
          <CountryPhone onChange={(code: any) => onChangePhone(code, val)} area={area} />
          <IonInput
            {...params}
            id={id}
            name={id}
            maxlength={area && area === 'BO' ? 9 : undefined}
            onIonBlur={onBlur}
            className="elevated-input"
            ref={inputRef}
            placeholder={label}
            defaultValue={''}
            onIonChange={(e: any) => onChangePhone(area, e.detail.value)}
            onIonInput={onInput}
            value={val}
            type={type}
          />

          {value ? (
            <IonLabel className="elevated-label" color="secondary" slot="end">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {label}
                {error && !loading ? <WarnIcon onClick={focusInput} /> : null}
              </div>
            </IonLabel>
          ) : null}
        </IonItem>

        {error || onRemove ? (
          <div className="elevated-error">
            {error}
            {onRemove ? (
              <IonLabel color="warning" onClick={onRemove}>
                Eliminar
              </IonLabel>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ElevatedPhone;
