import React, { useEffect, useRef } from "react";
import {
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer,
  Grid
} from "react-virtualized";
import CategoryBox from "./CategoryBox";

const cache = new CellMeasurerCache({
  defaultWidth: 80,
  defaultHeight: 100,
  fixedHeight: true
});

export function CategoryList({ data, toggleFilter, selectedCategory }: any) {
  const gridRef = useRef<any>(null);

  const cellRenderer = ({ columnIndex, key, parent, rowIndex, style }: any) => {
    const datum = data[columnIndex];
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={columnIndex}
        key={key}
        parent={parent}
        rowIndex={rowIndex}
      >
        <div style={style}>
          <CategoryBox
            category={datum}
            onClick={() => toggleFilter(datum)}
            selected={datum.id === (selectedCategory && selectedCategory.id)}
          />
        </div>
      </CellMeasurer>
    );
  };

  useEffect(() => {
    const grid = gridRef && gridRef.current;
    if (grid) {
      cache.clearAll();
    }
  }, [data]);

  return (
    <AutoSizer disableHeight>
      {({ width }) => {
        return (
          <Grid
            autoHeight={false}
            ref={gridRef}
            style={{ outline: "none" }}
            columnCount={data.length}
            columnWidth={cache.columnWidth}
            deferredMeasurementCache={cache}
            height={100}
            overscanColumnCount={0}
            overscanRowCount={0}
            cellRenderer={cellRenderer}
            rowCount={1}
            rowHeight={cache.defaultHeight}
            width={width}
          />
        );
      }}
    </AutoSizer>
  );
}
