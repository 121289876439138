import React from 'react';
import { IonHeader, IonButtons, IonBackButton, IonToolbar, IonTitle, IonButton, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import './Header.css';

export default function Header({ title, noBack = false, onClose }: any) {
  return (
    <IonHeader>
      <IonToolbar className="elevated-toolbar">
        <IonButtons slot="start">{noBack && onClose ? null : <IonBackButton text="" color="light" />}</IonButtons>
        <IonTitle color="light">{title}</IonTitle>
        <IonButtons slot="end">
          {!onClose ? null : (
            <IonButton color="light" onClick={onClose}>
              <IonIcon icon={close} />
            </IonButton>
          )}
        </IonButtons>
        <div className="elevated-toolbar-container"></div>
      </IonToolbar>
    </IonHeader>
  );
}
