import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { call, put, all, select, debounce, takeLatest } from 'redux-saga/effects';
import {
  addItemToCart,
  setError,
  deleteCart,
  setShoppingCart,
  saveCart,
  savePickingDetails,
  setPickingDetails,
  placeOrder,
  generateCopy,
  setLoadingState,
  setOrderNumber,
  clearLoading,
  setProductOptionValue,
  setProductComments,
  triggerCartSave
} from './cartReducer';
import { anonymousAuth } from '../services/services/anonymous';
import { sendOrderToStore } from '../services/services/orderService';
import { v4 } from 'uuid';

function* doPlaceOrder({ payload: { picking } }) {
  let { user, supplierStore, shop, cart } = yield select(({ cart }) => ({
    user: cart.user,
    supplierStore: cart.supplierStore,
    shop: cart.shop,
    cart: cart.cart
  }));

  yield put(setLoadingState(true, 'Enviando pedido'));
  try {
    if (!user) {
      user = yield call(anonymousAuth);
    }
    const token = yield call(window.executeRecaptcha);
    const result = yield call(sendOrderToStore, supplierStore.id, shop.id, cart, picking, token, user.uid);
    if (result && result.order) {
      yield put(generateCopy(cart));
      yield put(deleteCart());
      yield put(setOrderNumber(result, cart.guid));
      yield put(setLoadingState(false));
      yield put(savePickingDetails({ ...picking }));
    } else {
      throw Error('Algo salío mal, vuelve a intentar porfavor');
    }
  } catch (error) {
    yield put(setLoadingState(false));
    yield put(
      setError({
        code: '500',
        message: 'Ocurrio un error al enviar la orden, intenta de nuevo porfavor'
      })
    );
    console.error(error);
  }
}

function* doDeleteCart() {
  let { user, supplierStore, shop, cart, picking } = yield select(({ cart }) => ({
    user: cart.user,
    supplierStore: cart.supplierStore,
    shop: cart.shop,
    cart: cart.cart,
    picking: cart.picking
  }));

  try {
    if (cart) {
      // const { order, ...newPicking } = picking;
      // yield call(() =>
      //   db
      //     .collection('picking_details')
      //     .doc(`u:${user.uid}`)
      //     .set(newPicking)
      // );

      // yield call(() =>
      //   db
      //     .collection('current_carts')
      //     .doc(`s:${supplierStore.id}_b:${shop.id}_u:${user.uid}`)
      //     .delete()
      // );

      yield put(setShoppingCart(null));
    }
  } catch (error) {
    yield put(setShoppingCart(null));
  }
}

function* doGenerateCopy({ payload: { cart } }) {
  const db = firebase.firestore();
  yield call(() =>
    db
      .collection('cart_copy')
      .doc(`${cart.guid}`)
      .set(cart)
  );
}

function* doSavePickingDetails({ payload: { picking } }) {
  let { user } = yield select(({ cart }) => ({
    user: cart.user
  }));
  yield put(setPickingDetails(picking));
  try {
    if (!user) {
      user = yield call(anonymousAuth);
    }
    const db = firebase.firestore();
    picking.userId = user.uid;
    picking.location = picking.location ? { lat: picking.location.lat, lng: picking.location.lng } : null;
    picking.deliveryDate = null;
    picking.deliveryTime = null;
    picking.deliveryPrice = null;
    yield call(() =>
      db
        .collection('picking_details')
        .doc(`u:${user.uid}`)
        .set(picking)
    );
  } catch (error) {
    console.error(error);
  }
}

function* doSaveCart({ payload: { history, url } }) {
  let { supplierStore, shop, user, cart } = yield select(({ cart }) => ({
    supplierStore: cart.supplierStore,
    shop: cart.shop,
    user: cart.user,
    cart: cart.cart
  }));
  try {
    if (!supplierStore || !supplierStore.id || !shop || !shop.id) {
      return;
    }
    yield put(setLoadingState(true, 'Guardando carrito'));
    if (!user) {
      user = yield call(anonymousAuth);
    }
    const shoppingCart = { ...cart, guid: v4(), userId: user.uid };
    yield put(setShoppingCart(shoppingCart));
    yield put(setLoadingState(false, 'Guardando carrito'));
    yield put(triggerCartSave());
    history.push(`/${url}/checkout${history.location.search}`);
  } catch (error) {
    history.push(`/${url}/checkout${history.location.search}`);
    yield put(setLoadingState(false, 'Guardando carrito'));
    console.error('unable to save cart');
  }
}

function* pushCartToBackend() {
  let { supplierStore, shop, user, cart } = yield select(({ cart }) => ({
    supplierStore: cart.supplierStore,
    shop: cart.shop,
    user: cart.user,
    cart: cart.cart
  }));
  try {
    if (!supplierStore || !supplierStore.id || !shop || !shop.id) {
      return;
    }
    if (!user) {
      user = yield call(anonymousAuth);
    }
    cart.userId = user.uid;
    const db = firebase.firestore();
    yield call(() =>
      db
        .collection('current_carts')
        .doc(`s:${supplierStore.id}_b:${shop.id}_u:${user.uid}`)
        .set(cart)
    );
  } catch (error) {
    console.debug(error);
  }
}

function* doAddItemToCart({ payload: { product, qty = 1 } }) {
  let { supplierStore, shop, cart } = yield select(({ cart }) => ({
    supplierStore: cart.supplierStore,
    shop: cart.shop,
    user: cart.user,
    cart: cart.cart
  }));
  try {
    if (!supplierStore || !supplierStore.id || !shop || !shop.id) {
      console.error(`no supplier loaded in store ${supplierStore} ${shop}`);
      return;
    }
    const shoppingCart = (cart && { ...cart }) || { products: {} };
    if (!shoppingCart.products) {
      shoppingCart.products = {};
    }
    if (!qty) {
      delete shoppingCart.products[product.id];
    } else {
      const inCart = shoppingCart.products[product.id] || {};
      shoppingCart.products[product.id] = { ...inCart, ...product, qty };
    }
    shoppingCart.guid = shoppingCart.guid || v4();
    yield put(setShoppingCart(shoppingCart));
    yield put(triggerCartSave());
  } catch (error) {
    console.error(error);
    yield put(setShoppingCart(cart));
  }
}

function* doSetProductOptionValue({ payload: { inCartId, itemId, optionId, value } }) {
  let cart = yield select(({ cart }) => cart.cart);
  try {
    const inCart = cart && cart.products && cart.products[inCartId];
    if (!inCart) {
      return;
    }
    inCart.selections = inCart.selections || {};
    inCart.selections[itemId] = inCart.selections[itemId] || { id: itemId };
    inCart.selections[itemId][optionId] = value;
    const shoppingCart = (cart && { ...cart }) || { products: {} };
    shoppingCart.products = { ...shoppingCart.products, [inCartId]: inCart };
    yield put(setShoppingCart(shoppingCart));
    yield put(triggerCartSave());
  } catch (error) {
    console.error(error);
    yield put(setShoppingCart(cart));
  }
}

function* doSetProductComments({ payload: { inCartId, val } }) {
  let cart = yield select(({ cart }) => cart.cart);
  try {
    const inCart = cart && cart.products && cart.products[inCartId];
    if (!inCart) {
      return;
    }
    inCart.comments = val;
    const shoppingCart = (cart && { ...cart }) || { products: {} };
    shoppingCart.products = { ...shoppingCart.products, [inCartId]: inCart };
    yield put(setShoppingCart(shoppingCart));
    yield put(triggerCartSave());
  } catch (error) {
    console.error(error);
    yield put(setShoppingCart(cart));
  }
}

function* setTimer() {
  yield put(clearLoading());
  console.log('all timers reset');
}

export default function* cartSagas() {
  yield all([
    takeLatest(saveCart().type, doSaveCart),
    takeLatest(addItemToCart().type, doAddItemToCart),
    takeLatest(setProductOptionValue().type, doSetProductOptionValue),
    takeLatest(setProductComments().type, doSetProductComments),
    takeLatest(deleteCart().type, doDeleteCart),
    takeLatest(savePickingDetails().type, doSavePickingDetails),
    takeLatest(placeOrder().type, doPlaceOrder),
    takeLatest(generateCopy().type, doGenerateCopy),
    debounce(500, triggerCartSave().type, pushCartToBackend),
    debounce(10000, setLoadingState().type, setTimer)
  ]);
}
