import React from 'react';
import { MoneyDisplay } from '../../../components/Filters/Money';
import { IonLabel, IonPage, IonContent, IonGrid, IonRow, IonCol } from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import { useProductDetail } from '../services/hooks/useProductDetail';
import { useParams } from 'react-router';
import StoreFooter from './StoreFooter';
import Header from './StoreHeader';

import './ProductDetail.css';
import ProductAddButton from './ProductAddButton';
import ProductOptions from './ProductOptions/ProductOptions';
import { getDomainPath } from '../Main/customDomain';
import { Helmet } from 'react-helmet';

export function ProductDetail({ history }: any) {
  const dispatch = useDispatch();
  const { productId }: any = useParams();
  const store = useSelector(({ cart }: any) => cart.supplierStore);
  const logo = store && store.imageUrl;
  const shop = useSelector(({ cart }: any) => cart.shop);
  const cart = useSelector(({ cart }: any) => cart && cart.cart);
  const inCart = (cart && cart.products[productId]) || null;

  const [product, productDetailState] = useProductDetail(productId, {
    shopId: shop && shop.id
  });

  const backToShop = () => {
    if (!store || !shop) {
      return;
    }
    const { path } = getDomainPath(store.shortName);
    history.replace({
      pathname: `${path}store`,
      search: `?shop=${shop.id}`
    });
  };

  return (
    <IonPage>
      {store && product ? (
        <Helmet>
          <title>
            {store.name} - {product.name}
          </title>
          <meta name="description" content={product.description || product.name} />
          <meta name="keywords" content={`${store.name},${product.name}`} />
          <meta property="og:site_name" content={store.name} />
          <meta property="og:title" content={product.name} />
          <meta property="og:description" content={product.description || product.name} />
          <meta property="og:secure_url" data-itemprop="image" content={product.imageUrl || logo} />
          <meta property="og:image" data-itemprop="image" content={product.imageUrl || logo} />
          <meta property="og:type" content="website" />
          <meta property="og:updated_time" content="1440432930" />
        </Helmet>
      ) : null}
      <Header noSearch={true} backToShop={backToShop} />
      <IonContent className="body-container">
        {product ? (
          <div key={product.id} className="product-detail-container shadow-container">
            <IonGrid>
              <IonRow>
                <IonCol size="12" sizeLg="5">
                  <div className="product-detail-image">
                    <img alt="imagen" src={productDetailState !== 'ready' ? logo : product.imageUrl || logo} />
                  </div>
                </IonCol>
                <IonCol size="12" sizeLg="7">
                  <div className="product-detail-resume">
                    <div className="product-detail-pricing">
                      <IonLabel className="price-tag">
                        {' '}
                        Bs <MoneyDisplay value={product.flags.salePrice}> </MoneyDisplay>{' '}
                      </IonLabel>
                    </div>

                    <div className="no-big-screen">
                      <ProductAddButton noJump={true} product={product} inCart={inCart} />
                    </div>
                  </div>

                  <div className="product-detail-resume">
                    <div className="product-detail-resume-data">
                      <IonLabel className="product-name">{product.name}</IonLabel>
                      <IonLabel className="product-uom">{product.uom}</IonLabel>
                    </div>
                  </div>
                  <div className="product-detail-resume big-screen">
                    <ProductAddButton noJump={true} product={product} inCart={inCart} />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid>
              <IonRow>
                <IonCol size="12" sizeMd="7" pushMd="5">
                  <ProductOptions inCart={inCart} product={product}></ProductOptions>
                </IonCol>
                <IonCol size="12" sizeMd="5" pullMd="7">
                  {product.description ? (
                    <div className="product-detail-description">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: product.description || ''
                        }}
                      />
                    </div>
                  ) : null}
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : null}
      </IonContent>
      <StoreFooter />
    </IonPage>
  );
}
