import React, { useRef } from 'react';
import { IonSelect, IonSelectOption, IonIcon } from '@ionic/react';
import { IonLabel, IonSpinner } from '@ionic/react';
import WarnIcon from './WarnIcon';
import './ElevatedSelect.css';
import { chevronDown } from 'ionicons/icons';
export default function ElevatedDropdown({
  onChange,
  value = '',
  items,
  loading,
  meta,
  label,
  itemKey = 'key',
  itemValue = 'string'
}: any) {
  let selectInput = useRef<any>(null);
  const error = meta && meta.error;

  return (
    <div className="elevated-box">
      <div className="elevated-container">
        <div
          onClick={() => {
            selectInput && selectInput.current && selectInput.current.open();
          }}
          style={{ cursor: 'pointer' }}
          className="elevated-subcontainer"
        >
          <IonLabel color="primary" className="select-label">
            {value ? value[itemValue] : label} {!value || !value[itemValue] ? <IonIcon icon={chevronDown} /> : null}
          </IonLabel>
          <IonSelect
            slot="start"
            ref={selectInput}
            style={{ display: 'none' }}
            // interface="popover"
            placeholder={label}
            onIonChange={e => onChange(e.detail.value)}
            value={value}
            cancelText="Cancelar"
            okText="Confirmar"
          >
            {!loading
              ? items.map((it: any) => (
                  <IonSelectOption key={it[itemKey]} value={it}>
                    {it[itemValue]}
                  </IonSelectOption>
                ))
              : null}
          </IonSelect>

          <IonLabel className="elevated-label" color="secondary" slot="end">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {(value && label) || null}
              {error && !loading ? (
                <WarnIcon></WarnIcon>
              ) : loading ? (
                <div style={{ width: '1rem', marginLeft: '0.5rem' }}>
                  <IonSpinner color="primary"></IonSpinner>
                </div>
              ) : null}
            </div>
          </IonLabel>
        </div>
        {error ? <div className="elevated-error">{error}</div> : null}
      </div>
    </div>
  );
}
