import React, { useState, useRef, useReducer, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonToolbar,
  IonHeader,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButtons,
  IonButton,
  IonTitle,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol
} from '@ionic/react';
import { useSelector, useDispatch } from 'react-redux';
import StoreCart from './StoreCart';
import DetailDetail from './DetailDetail';
import OrderConfirmation from './OrderConfirmation';
import useShoppingCart from '../services/hooks/useShoppingCart';
import { arrowBack } from 'ionicons/icons';
import Footer from '../../../components/Footer';
import {
  addItemToCart,
  placeOrder,
  deleteCart,
  setError,
  setPickingDetails,
  setNotification
} from '../redux/cartReducer';
import { Helmet } from 'react-helmet';

import './Checkout.css';
import useUser from '../services/hooks/useUser';
import { Grid } from 'react-virtualized';
import CheckoutTotals from './CheckoutTotals';
import PickingDetail from './PickingDetail';
import usePickingDetails from '../services/hooks/usePickingDetails';
import { getDomainPath } from '../Main/customDomain';
import { getIncompleteOrders } from '../getIncompleteOrders';

const Checkout: React.FC = ({ history }: any) => {
  const pickingRef = useRef<any>(null);
  const detailRef = useRef<any>(null);
  const [pickingData, setPickingData] = useState<any>(null);
  const [detailData, setDetailData] = useState<any>(null);
  const [step, setStep] = useState<any>('cart');
  const supplierStore = useSelector(({ cart }: any) => cart.supplierStore);
  const shop = useSelector(({ cart }: any) => cart.shop);
  const order = useSelector(({ cart }: any) => cart.orderNumber);
  const cartCopy = useSelector(({ cart }: any) => cart.cartCopy);

  const [user] = useUser();

  const dispatch = useDispatch();

  const backToShop = () => {
    if (step === 'cart' || step === 'order') {
      if (!supplierStore || !shop) {
        return;
      }
      setStep('cart');
      const { path } = getDomainPath(supplierStore.shortName);
      history.replace({
        pathname: `${path}store`,
        search: `?shop=${shop.id}`
      });
      return;
    } else {
      if (step === 'detail') {
        setStep('picking');
        return;
      }

      if (step === 'picking') {
        setStep('cart');
        return;
      }
    }
  };

  const [cart, cartState, meta] = useShoppingCart(supplierStore && supplierStore.id, shop && shop.id);
  const [picking] = usePickingDetails();
  // const picking: any = useSelector(({ cart }: any) => cart.picking);

  const whatsappLink = (order && order.whatsapp) || (picking && picking.order && picking.order.whatsapp);

  useEffect(() => {
    if ((picking && picking.order && picking.order.order) || (order && order.order)) {
      setStep('order');
    }
  }, [picking, order]);

  const removeItemFromCart = (product: any) => {
    dispatch(addItemToCart(product, 0));
  };

  const onDetail = (product: any) => {
    const { path } = getDomainPath(supplierStore.shortName);
    history.push({
      pathname: `${path}store/product/${product.id}`,
      search: `?shop=${shop.id}`
    });
  };

  const handleStepTransition = () => {
    if (step === 'cart') {
      const incompleteOrders = getIncompleteOrders({ items: cart.products });
      if (incompleteOrders && incompleteOrders.length) {
        dispatch(setNotification('Por favor selecciona las opciones para tu orden'));
        const firstIncomplete = incompleteOrders[0];
        if (firstIncomplete) {
          const { path } = getDomainPath(supplierStore.shortName);
          history.push({
            pathname: `${path}store/product/${firstIncomplete.id}`,
            search: `?shop=${shop.id}`
          });
        }
        return;
      }
      if (user) {
        setStep('picking');
      }
    }

    if (step === 'picking') {
      pickingRef.current.submit();
    }

    if (step === 'detail') {
      detailRef.current.submit();
    }
  };

  const getPickingDetail = (data: any) => {
    setPickingData(data);
    setStep('detail');
  };

  const handleSubmit = (data: any) => {
    dispatch(placeOrder({ ...data, ...pickingData }));
  };

  const handleShare = () => {
    window.open(whatsappLink);
    dispatch(deleteCart());
    setStep('cart');
    backToShop();
  };

  return (
    <IonPage>
      {supplierStore ? (
        <Helmet>
          <title>{supplierStore.name} - Checkout</title>
          <meta name="description" content={supplierStore.description} />
          <meta name="keywords" content={`${supplierStore.name},pedidos online`} />
          <meta property="og:site_name" content={supplierStore.name} />
          <meta property="og:title" content={supplierStore.name} />
          <meta property="og:description" content={supplierStore.description || supplierStore.name} />
          <meta property="og:secure_url" data-itemprop="image" content={supplierStore.imageUrl} />
          <meta property="og:image" data-itemprop="image" content={supplierStore.imageUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:updated_time" content="1440432930" />
        </Helmet>
      ) : null}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={backToShop} color="primary">
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
          <IonTitle>
            <IonLabel color="primary">Carrito</IonLabel>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonHeader>
        <IonToolbar className="category-header no-padding">
          <IonSegment
            mode="md"
            value={step}
            disabled={step === 'order'}
            onIonChange={(e: any) => {
              setStep(e.detail.value);
            }}
          >
            <IonSegmentButton value="cart">
              <IonLabel>Carrito</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton disabled={step === 'cart'} value="picking">
              <IonLabel>Entrega</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton disabled={step === 'cart' || step === 'picking'} value="detail">
              <IonLabel>Detalles</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton disabled={step !== 'order'} value="order">
              <IonLabel>Orden</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent forceOverscroll={false} scrollY={step !== 'cart' && step !== 'order'}>
        <IonGrid className="no-padding" fixed style={{ height: '100%' }}>
          <IonRow style={{ height: '100%' }}>
            <IonCol sizeMd="8" size="12">
              {step === 'cart' ? (
                cart ? (
                  <StoreCart onDetail={onDetail} onRemove={removeItemFromCart} cart={cart && cart.products} />
                ) : null
              ) : null}

              {step === 'picking' ? (
                cart ? (
                  <PickingDetail data={pickingData} onSubmit={getPickingDetail} ref={pickingRef} />
                ) : null
              ) : null}
              {step === 'detail' ? (
                cart ? (
                  <DetailDetail data={detailData} onSubmit={handleSubmit} ref={detailRef} />
                ) : null
              ) : null}

              {/* {step === 'payment' ? cart ? <PaymentDetail onSubmit={handleSubmit} /> : null : null} */}

              {step === 'order' && ((order && order.order) || (picking.order && picking.order.order)) ? (
                <OrderConfirmation
                  cartCopy={cartCopy}
                  store={supplierStore}
                  onShare={handleShare}
                  {...(order || picking.order)}
                />
              ) : null}
            </IonCol>
            <IonCol className="show-if-big" sizeMd="4">
              <CheckoutTotals
                step={step}
                supplierStore={supplierStore}
                meta={meta}
                handleStepTransition={handleStepTransition}
              ></CheckoutTotals>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <Footer hideIfBig={true}>
        <CheckoutTotals
          step={step}
          supplierStore={supplierStore}
          meta={meta}
          handleStepTransition={handleStepTransition}
        ></CheckoutTotals>
      </Footer>
    </IonPage>
  );
};

export default Checkout;
