import React from 'react';
import useShoppingCart from '../services/hooks/useShoppingCart';
import { IonFooter, IonLabel, IonButton } from '@ionic/react';
import { MoneyDisplay } from '../../../components/Filters/Money';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { saveCart } from '../redux/cartReducer';

export default function StoreFooter() {
  const store = useSelector(({ cart }: any) => cart.supplierStore);
  const shop = useSelector(({ cart }: any) => cart.shop);
  const history = useHistory();
  const dispatch = useDispatch();

  const openCart = () => {
    dispatch(saveCart(history, `${store.shortName}/store`));
  };

  const [shoppingCart, _, meta] = useShoppingCart(store && store.id, shop && shop.id);
  const { totalAmount, totalQty } = meta || {};

  return (
    <IonFooter className="total-footer">
      <IonButton onClick={() => {}} fill="clear">
        <IonLabel className="total-footer small" color="warning">
          {store
            ? (store.deliveryType === 'free' && 'Envío gratis') ||
              (store.deliveryType === 'limit' && `Mínimo ${store.deliveryLimit} Bs`) ||
              (store.deliveryType === 'variable' && '')
            : null}
        </IonLabel>
      </IonButton>

      <IonButton onClick={openCart} fill="clear">
        <IonLabel key={totalAmount} className="total-footer" color="primary">
          <IonLabel className="total-footer small">
            {totalQty ? totalQty : ''} {'items'}
          </IonLabel>
          {' Bs.'}
          {totalAmount ? <MoneyDisplay value={totalAmount} /> : ''}
        </IonLabel>
      </IonButton>
    </IonFooter>
  );
}
