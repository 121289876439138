import * as firebase from 'firebase/app';
import 'firebase/firestore';

export async function retriveCartCopy(cartId) {
  const db = firebase.firestore();
  const cartRef = db.collection('cart_copy').doc(cartId);

  const cart = await cartRef.get();
  if (cart.exists) {
    return { ...cart.data(), id: cart.id };
  } else {
    return null;
  }
}

export async function retriveCurrentCartFor(supplierId, shopId, uid) {
  // return null;
  try {
    const db = firebase.firestore();
    const cartKey = `s:${supplierId}_b:${shopId}_u:${uid}`;
    console.log(cartKey);
    const cartRef = db.collection('current_carts').doc(cartKey);
    const cart = await cartRef.get();
    if (cart && cart.exists) {
      return { ...cart.data(), id: cart.id };
    } else {
      return null;
    }
  } catch (error) {
    // console.error(error);
    return null;
  }
}

export async function retrivePickingDetails(uid) {
  const db = firebase.firestore();
  const pickingRef = db.collection('picking_details').doc(`u:${uid}`);
  const picking = await pickingRef.get();
  try {
    if (picking && picking.exists) {
      return picking.data();
    } else {
      return {};
    }
  } catch (error) {
    return {};
  }
}
