import React, { useState, useEffect } from "react";
import {
  IonButton,
  IonIcon,
  IonLabel,
  IonSlides,
  IonSlide
} from "@ionic/react";
import CategoryBox from "./CategoryBox";
import { useSelector, useDispatch } from "react-redux";
import { searchOutline } from "ionicons/icons";
import {
  setSelectedCategory,
  setShowSearchBox,
  setFilters
} from "../../redux/cartReducer";
import ElevatedSearchInput from "../../../../components/form/ElevatedSearchInput";
import AppForm from "../../../../components/form/AppForm";
import { CategoryList } from "./CategoryList";

export default function CategoriesSlider({
  defaultSearch,
  searchMode,
  categories = [],
  shopId
}: any) {
  const [search, setSearch] = useState<any>(defaultSearch);
  const selectedCategory = useSelector(
    ({ cart }: any) => cart.selectedCategory
  );
  const dispatch = useDispatch();
  const toggleFilter = (category: any) => {
    return dispatch(setSelectedCategory(category));
  };
  const dispatchSetFilters = () => {
    dispatch(setFilters({ search }));
  };

  useEffect(() => {
    if (!search) {
      dispatchSetFilters();
    }
  }, [search]);

  if (!shopId) {
    return null;
  }

  if (!searchMode) {
    return null;
  }

  return (
    <div>
      <div className="category-slide-header">
        <IonLabel>Búsqueda</IonLabel>
      </div>
      <AppForm onSubmit={() => dispatchSetFilters()}>
        <ElevatedSearchInput
          value={search}
          onIonChange={(e: any) => {
            setSearch(e.detail.value);
          }}
          placeholder="Buscar ..."
          onSearch={dispatchSetFilters}
        />
      </AppForm>
      <div className="category-slide-header">
        <IonLabel>Explorar Categorías</IonLabel>
      </div>
      <CategoryList
        data={categories}
        selectedCategory={selectedCategory}
        toggleFilter={toggleFilter}
      />
    </div>
  );
}
