import React from 'react';
import _ from 'lodash';
import ProductSelection from './ProductSelection';

export default function ProductSelections({ options, selection, setSelection }: any) {
  const iterOptions = _.sortBy(Object.values(options), 'sequence');
  return (
    <>
      {iterOptions.map((option: any) => (
        <ProductSelection
          setOptionSelection={(val: any) => {
            setSelection(option.id, option.name, val);
          }}
          key={option.id}
          option={option}
          optionSelection={(selection[option.id] && selection[option.id].value) || null}
        ></ProductSelection>
      ))}
    </>
  );
}
