import React, { useRef } from 'react';
import { IonItem, IonTextarea, IonLabel } from '@ionic/react';
import WarnIcon from './WarnIcon';
import './ElevatedTextarea.css';

const ElevatedTextarea = ({
  type,
  onChange,
  onInput,
  onBlur,
  onRemove,
  value,
  label,
  id,
  meta,
  loading,
  ...params
}: any) => {
  let inputRef = useRef<any>();
  const error = meta && meta.error;
  const focusInput = () => {
    if (inputRef && inputRef.current !== null) {
      inputRef.current.setFocus();
    }
  };
  return (
    <div className="elevated-box">
      <div className="elevated-container">
        <div className={`elevated-item ${error ? 'ion-invalid' : ''}`} onClick={focusInput}>
          <div className="textarea-container">
            {label && value ? <IonLabel color="primary">{label}</IonLabel> : null}
            <IonTextarea
              {...params}
              id={id}
              style={{ textAlign: 'left' }}
              name={id}
              onIonBlur={onBlur}
              ref={inputRef}
              placeholder={label}
              defaultValue={''}
              onIonChange={onChange}
              onIonInput={onInput}
              value={value}
              type={type}
            />
          </div>
        </div>

        {error || onRemove ? (
          <div className="elevated-error">
            {error}
            {onRemove ? (
              <IonLabel color="warning" onClick={onRemove}>
                Eliminar
              </IonLabel>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ElevatedTextarea;
