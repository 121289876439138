export function isCustomDomain() {
  const host = window.location.hostname;
  const isCustom = host !== 'abasto.app' && host !== 'localhost';
  return isCustom;
}

export function getDomainPath(storeKey) {
  let path = '';
  let origin = '';
  let key = '';
  if (isCustomDomain()) {
    path = '/';
    origin = '/';
  } else {
    path = `/${storeKey}/`;
    origin = `/${storeKey}`;
    key = `${storeKey}/`;
  }
  return { path, origin, key };
}
