import { createActions, handleActions } from 'redux-actions';
const defaultState = {
  notification: '',
  filters: {},
  showSearchBox: false,
  supplierStore: null,
  loading_calls: [],
  shop: null,
  supplier: '',
  cart: null,
  selectedCategory: null,
  forceLogin: true,
  picking: {},
  orderNumber: null,
  cartCopy: null,
  deliveryPrice: null,
  scrollIndex: undefined
};

export const {
  setNotification,
  setShoppingCart,
  setStore,
  clear,
  setLoadingState,
  clearLoading,
  setError,
  setUser,
  addItemToCart,
  setSelectedCategory,
  setForceLogin,
  setPickingDetails,
  savePickingDetails,
  placeOrder,
  setOrderNumber,
  setShowSearchBox,
  setFilters,
  deleteCart,
  saveCart,
  generateCopy,
  setScrollIndex,
  setDeliveryPrice,
  setProductOptionValue,
  setProductComments,
  triggerCartSave
} = createActions({
  SET_NOTIFICATION: notification => ({ notification }),
  SET_SHOPPING_CART: cart => ({ cart }),
  SET_STORE: (supplierStore, shop) => ({ supplierStore, shop }),
  CLEAR: () => ({}),
  SET_LOADING_STATE: (loading, message) => ({ loading, message }),
  CLEAR_LOADING: () => ({}),
  SET_ERROR: error => ({ error }),
  SET_USER: user => ({ user }),
  ADD_ITEM_TO_CART: (product, qty) => ({ product, qty }),
  SET_SELECTED_CATEGORY: selectedCategory => ({ selectedCategory }),
  SET_FORCE_LOGIN: forceLogin => ({ forceLogin }),
  SET_PICKING_DETAILS: picking => ({ picking }),
  SAVE_PICKING_DETAILS: picking => ({ picking }),
  PLACE_ORDER: picking => ({ picking }),
  SET_ORDER_NUMBER: (orderNumber, cartCopy) => ({ orderNumber, cartCopy }),
  SET_SHOW_SEARCH_BOX: showSearchBox => ({ showSearchBox }),
  SET_FILTERS: filters => ({ filters }),
  DELETE_CART: () => ({}),
  SAVE_CART: (history, url) => ({ history, url }),
  GENERATE_COPY: cart => ({ cart }),
  SET_SCROLL_INDEX: index => ({ index }),
  SET_DELIVERY_PRICE: deliveryPrice => ({ deliveryPrice }),
  SET_PRODUCT_OPTION_VALUE: (inCartId, itemId, optionId, value) => ({ inCartId, itemId, optionId, value }),
  SET_PRODUCT_COMMENTS: (inCartId, val) => ({ inCartId, val }),
  TRIGGER_CART_SAVE: () => ({})
});

const reducer = handleActions(
  {
    [setNotification]: (state, { payload: { notification } }) => {
      return { ...state, notification };
    },
    [clear]: () => {
      return defaultState;
    },
    [setShoppingCart]: (state, { payload: { cart } }) => {
      return { ...state, cart };
    },
    [setStore]: (state, { payload: { supplierStore, shop } }) => {
      return { ...state, supplierStore, shop };
    },
    [setLoadingState]: (state, { payload: { loading, message } }) => {
      const loading_calls = [...state.loading_calls];
      if (loading) {
        loading_calls.push(true);
      } else {
        loading_calls.pop();
      }
      const isLoading = !!loading_calls.length;
      return { ...state, loading: isLoading, loading_calls, message };
    },
    [clearLoading]: state => {
      return { ...state, loading: false, loading_calls: [] };
    },
    [setError]: (state, { payload: { error } }) => {
      return { ...state, error };
    },
    [setUser]: (state, { payload: { user } }) => {
      return { ...state, user };
    },
    [setSelectedCategory]: (state, { payload: { selectedCategory } }) => {
      let selCategory = selectedCategory;
      if (selectedCategory && state.selectedCategory && state.selectedCategory.id === selectedCategory.id) {
        selCategory = null;
      }
      return {
        ...state,
        selectedCategory: selCategory
      };
    },
    [setForceLogin]: (state, { payload: { forceLogin } }) => {
      return { ...state, forceLogin };
    },
    [setPickingDetails]: (state, { payload: { picking } }) => {
      return { ...state, picking };
    },
    [setOrderNumber]: (state, { payload: { orderNumber, cartCopy } }) => {
      return { ...state, orderNumber, cartCopy };
    },
    [setShowSearchBox]: (state, { payload: { showSearchBox } }) => {
      return { ...state, showSearchBox };
    },
    [setFilters]: (state, { payload: { filters } }) => {
      return { ...state, filters, showSearchBox: false };
    },
    [setScrollIndex]: (state, { payload: { index } }) => {
      return { ...state, scrollIndex: index };
    },
    [setDeliveryPrice]: (state, { payload: { deliveryPrice } }) => {
      return { ...state, deliveryPrice };
    }
  },
  defaultState
);

export default reducer;
