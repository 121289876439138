import React, { Component, createRef } from 'react';
import { IonModal, IonLabel, IonButton, IonImg, IonIcon, IonContent, IonLoading } from '@ionic/react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import MapPlaceholder from '../assets/images/map_placeholder.png';
import location from '../assets/images/picking.svg';
import './BranchLocationPickup.css';
import Footer from './Footer';
import PickingZoneDrawing from './PickingZoneDrawing';
import L from 'leaflet';
import * as leafletPip from '@mapbox/leaflet-pip';

const token = 'pk.eyJ1IjoicmxvcGV6bSIsImEiOiJjazhid2NlYTQwYXR0M2VucHp6bmRoZjB5In0.-M6gloJUmgPB-g7nY32aYg';

const reverseGeocode = (latlng: any) => {
  const win: any = window;
  var geocoder = new win.google.maps.Geocoder();
  return new Promise((resolve, reject) => {
    geocoder.geocode({ location: latlng }, function(results: any, status: any) {
      if (status === 'OK') {
        if (results[0]) {
          const feature = results[0];
          resolve(feature.formatted_address);
        } else {
          resolve('No identificado');
        }
      } else {
        reject(status);
        console.error(status);
      }
    });
  });
};

export default class BranchLocationPickup extends Component<any> {
  locateOptions = {
    postion: 'topright',
    string: { title: 'Mostrar mi ubicación' }
  };
  state = {
    locating: false,
    latlng: this.props.latlng || null,
    position: this.props.latlng || {
      lat: -18.245,
      lng: -65.27
    },
    hasLocation: false,
    zoom: this.props.latlng ? 16 : 5,
    showMap: false
  };
  mapRef = createRef<any>();

  handleClick = (e: any) => {
    const map = this.mapRef.current;
    this.setState({ latlng: e.latlng, hasLocation: true });
  };

  findMyLocation = () => {
    this.mapRef && this.mapRef.current && this.mapRef.current.leafletElement.locate({ setView: true });
    this.setState({ locating: true });
  };

  handleLocationFound = (e: any) => {
    this.setState({ locating: false });
    if (e && e.latlng) {
      this.setState({
        latlng: e.latlng,
        hasLocation: true
      });
      if (this.mapRef && this.mapRef.current) {
        this.mapRef.current.leafletElement.flyTo(e.latlng);
        this.mapRef.current.leafletElement.setZoom(16);
      }
    }
  };

  handleLocationError = (e: any) => {
    this.setState({ locating: false });
    alert('Debes activar los permisos de ubicación para que podamos ubicarte en el mapa');
    console.error(e);
  };

  setDeliveryZone = (latlng: any) => {
    if (!this.props.zones) {
      return;
    }
    this.props.zones.forEach((zone: any) => {
      const geoJSON = zone.features;
      let leafletGeoJSON = new L.GeoJSON(geoJSON);
      const matches = leafletPip.pointInLayer(latlng, leafletGeoJSON);
      if (matches && matches.length) {
        return this.props.setDeliveryPrice(zone);
      }
    });
  };

  render() {
    const marker = this.state.latlng ? (
      <Marker position={this.state.latlng}>
        <Popup>Ubicación</Popup>
      </Marker>
    ) : null;
    const screenshot =
      this.props.latlng &&
      `https://api.mapbox.com/styles/v1/mapbox/dark-v10/static/${this.props.latlng.lng},${this.props.latlng.lat},16.13/300x200?access_token=${token}`;
    return (
      <React.Fragment>
        <div className="elevated-box">
          <div className="elevated-container">
            {this.props.simpleView ? (
              <div className="map-small-container">
                <IonLabel color={this.props.latlng ? 'primary' : 'warning'}>
                  {this.props.latlng ? `Enviar a: ${this.props.address}` : 'Selecionar ubicación de envío'}
                </IonLabel>
                <IonButton
                  fill="clear"
                  onClick={() =>
                    this.setState({ showMap: true }, () => {
                      setTimeout(() => {
                        if (this.mapRef && this.mapRef.current) {
                          this.mapRef.current.leafletElement.invalidateSize();
                        }
                        if (!this.props.latlng) {
                          setTimeout(() => {
                            this.findMyLocation();
                          }, 500);
                        }
                      }, 500);
                    })
                  }
                >
                  <IonIcon color="primary" icon={location} />
                </IonButton>
              </div>
            ) : (
              <div className="elevated-subcontainer map-screenshot-container">
                <div className="map-screenshot">
                  <IonImg
                    onClick={() =>
                      this.setState({ showMap: true }, () => {
                        setTimeout(() => {
                          this.mapRef.current.leafletElement.invalidateSize();
                        }, 500);
                      })
                    }
                    src={!this.props.latlng ? MapPlaceholder : screenshot}
                  />
                </div>
                {!this.props.latlng ? <IonLabel color="warning">Seleccionar una ubicación</IonLabel> : null}
              </div>
            )}
          </div>
        </div>
        <IonModal
          onDidDismiss={() => {
            this.setState({ showMap: false });
          }}
          animated={false}
          isOpen={this.state.showMap}
          showBackdrop={true}
          swipeToClose={false}
        >
          <IonContent>
            <IonLoading isOpen={this.state.locating} message="Ubicando" />
            <div className="elevated-map-container">
              <Map
                trackResize={false}
                tap={true}
                ref={this.mapRef}
                onclick={this.handleClick}
                minZoom={5}
                maxBounds={[
                  [-22.9, -69.65],
                  [-9.67, -57.45]
                ]}
                onlocationerror={this.handleLocationError}
                onlocationfound={this.handleLocationFound}
                center={this.props.latlng || this.state.position}
                zoom={this.props.latlng ? 16 : this.state.zoom}
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {marker}
                <PickingZoneDrawing zones={this.props.zones}></PickingZoneDrawing>
              </Map>
            </div>
          </IonContent>
          <Footer className="ion-no-border ion-padding-start ion-padding-end">
            <IonButton className="transparent" size="large" expand="block" onClick={this.findMyLocation}>
              Usar Mi Ubicación
            </IonButton>

            <IonButton
              size="large"
              expand="block"
              onClick={async () => {
                this.setState({ showMap: false });
                const address = await reverseGeocode(this.state.latlng);
                this.setDeliveryZone(this.state.latlng);
                this.props.onSelect(this.state.latlng, address);
              }}
            >
              Confirmar
            </IonButton>
          </Footer>
        </IonModal>
      </React.Fragment>
    );
  }
}
