import React from 'react';
import { IonModal, IonToolbar, IonTitle, IonHeader, IonContent, IonList, IonItem, IonLabel } from '@ionic/react';

export default function ShopSelector({ isOpen, onSelect, shops }: any) {
  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Selecciona una tienda</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          {shops.map((shop: any) => {
            return (
              <IonItem key={shop.id} button onClick={() => onSelect(shop)}>
                <IonLabel>{shop.name}</IonLabel>
              </IonItem>
            );
          })}
        </IonList>
      </IonContent>
    </IonModal>
  );
}
