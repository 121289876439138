import React from 'react';
import MarketPlace from '../MarketPlace/MarketPlaceHome';
import { IonRouterOutlet } from '@ionic/react';
import { Route, useRouteMatch, Redirect } from 'react-router';
import SupplierStore from '../Store/Store';
import useLoadApp from './useLoadApp';
import CartCopy from '../Store/CartCopy';
import Checkout from '../Store/Checkout';
import { ProductDetail } from '../Store/ProductDetail';
import { isCustomDomain, getDomainPath } from './customDomain';

export default function StoreAccess() {
  const match: any = useRouteMatch();
  let storeKey = match.params && match.params.id;
  const { path, origin } = getDomainPath(storeKey);
  useLoadApp();
  return (
    <IonRouterOutlet>
      <Route path={`${path}store`} component={SupplierStore} exact={true} />
      <Route path={`${path}store/product/:productId`} component={ProductDetail} exact={true} />
      <Route path={`${path}store/checkout`} component={Checkout} exact={true} />
      <Route path="/mi_copia/:id" component={CartCopy} exact={true} />
      <Route
        exact={true}
        path={`${origin}`}
        render={(props: any) => (
          <Redirect
            from={`${origin}`}
            to={{ pathname: `${path}store`, search: props && props.location.search }}
          ></Redirect>
        )}
      ></Route>
    </IonRouterOutlet>
  );
}
