import React from "react";
import { useSelector } from "react-redux";

export function Banner({ style }: any) {
  const store = useSelector(({ cart }: any) => cart.supplierStore);
  const bannerUrl = `https://res.cloudinary.com/erguxabasto/image/upload/${"w_750,q_auto,f_auto"}/${store &&
    store.bannerUrl}`;
  return (
    <div
      style={{
        ...style
      }}
    >
      <div className="company-banner">
        <img src={bannerUrl} />
      </div>
    </div>
  );
}
