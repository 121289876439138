import React from 'react';
import { add, remove, cartOutline, chevronForwardOutline } from 'ionicons/icons';
import { MoneyDisplay } from '../../../components/Filters/Money';
import { IonIcon, IonLabel, IonButton, IonImg, IonInput } from '@ionic/react';
import ProductUom from './ProductUom';
import { useSelector, useDispatch } from 'react-redux';
import { addItemToCart } from '../redux/cartReducer';
import ProductAddButton from './ProductAddButton';
import ProductOptionsResume from './ProductOptions/ProductOptionsResume';

export function flattenProducts(products: any[]) {
  const prods = products.map((product: any) => {
    return { type: 'product', data: product, id: `product${product.id}` };
  });
  return prods;
}

export function ProductWidget({ data: product, onDetail, style, rowOnly, margin }: any) {
  const cart = useSelector(({ cart }: any) => cart.cart);
  const logo = useSelector(({ cart }: any) => cart.supplierStore && cart.supplierStore.imageUrl);
  const inCart = cart && cart.products && cart.products[product.id];

  return (
    <div
      style={{ ...style, marginRight: margin, marginLeft: margin }}
      className={`listing-product-item ${rowOnly ? 'as-row' : 'as-box'}`}
    >
      <div onClick={() => (onDetail ? onDetail(product) : null)} className="listing-product-box">
        <div className={`listing-product ${rowOnly ? 'as-row' : 'as-box'}`}>
          <div>
            <div className="listing-image-container">
              <img alt="producto" className="listing-image" src={product.imageUrl || logo} />
            </div>
          </div>
          <div className="listing-detail">
            <div className="listing-detail-1">
              <div className="listing-detail-price">
                <IonLabel className="product-name">{product.name}</IonLabel>
                <IonLabel className="product-uom">
                  <ProductUom value={product.uom} />
                </IonLabel>
              </div>
            </div>
            <div className="listing-detail-2">
              <IonLabel className="price-tag">
                {' '}
                Bs <MoneyDisplay value={product.flags.salePrice}> </MoneyDisplay>{' '}
              </IonLabel>
              <ProductAddButton onDetail={onDetail} product={product} inCart={inCart} />
            </div>

            {rowOnly ? (
              <div className="listing-detail-options">
                <ProductOptionsResume options={product.options} inCart={inCart} />
              </div>
            ) : null}

            {/* <div className="listing-detail-3">
              <ProductAddButton product={product} inCart={inCart} />
            </div> */}
          </div>
          <IonIcon color="primary" icon={chevronForwardOutline} onClick={() => onDetail && onDetail(product)} />
        </div>
      </div>
    </div>
  );
}
