import React from 'react';
import { IonLabel, IonButton } from '@ionic/react';
import { MoneyDisplay } from '../../../components/Filters/Money';
import { useSelector } from 'react-redux';
import { composeToolTip } from '../../../components/PickingZoneDrawing';

export default function CheckoutTotals({ step, supplierStore, meta, handleStepTransition }: any) {
  const deliveryPrice = useSelector(({ cart }: any) => cart.deliveryPrice);
  return (
    <>
      {step !== 'order' ? (
        <>
          <div className="checkout-footer">
            <div className="supplier">{supplierStore && supplierStore.name}</div>
            <div className="info">
              <div className="qty-info">
                <IonLabel>{meta && meta.totalQty} Productos</IonLabel>
                <IonLabel>Total</IonLabel>
              </div>
              <div className="amount-info">{meta && <MoneyDisplay value={meta.totalAmount} />} Bs.</div>
            </div>
            <div className="checkout-message">{deliveryPrice && composeToolTip(deliveryPrice)}</div>
            <IonButton onClick={handleStepTransition} size="small" expand="block" color="primary">
              {((step === 'cart' || step === 'picking') && <IonLabel>Siguiente</IonLabel>) ||
                (step === 'detail' && <IonLabel>Generar Pedido</IonLabel>)}
            </IonButton>
          </div>
        </>
      ) : null}
    </>
  );
}
