import { useState } from 'react';
import { fetchCollection } from '../services/fetch';
import { usePublicRequest } from './usePublicRequest';

export function useCollection(collection: string, query: any = {}): any {
  const [records, setRecords] = useState([]);
  const [count, setCount] = useState(0);
  const [state, setState] = useState('none');
  const offset = query && query.pagination && query.pagination.offset ? query.pagination.offset : 0;
  const limit = query && query.pagination && query.pagination.limit ? query.pagination.limit : 50;
  const sortBy = query && query.sortBy ? JSON.stringify(query.sortBy) : null;
  const search = query && query.search ? JSON.stringify(query.search) : null;

  const fetchRecords = async () => {
    if (state === 'fetching') {
      return;
    }
    setState('fetching');
    try {
      const data = await fetchCollection(collection, { ...query, pagination: { limit, offset } });
      if (data) {
        const records = (data && data.records) || [];
        const count = (data && data.count) || 0;
        setRecords(records);
        setCount(count);
      }
      setState('ready');
    } catch (error) {
      setState('error');
    }
  };

  usePublicRequest(fetchRecords, [collection, offset, limit, sortBy, search]);

  return [records, state, count];
}
