import { Animation } from "@ionic/core";
import { createAnimation } from "@ionic/react";

export const getIonPageElement = (element: HTMLElement) => {
  if (element.classList.contains("ion-page")) {
    return element;
  }

  const ionPage = element.querySelector(
    ":scope > .ion-page, :scope > ion-nav, :scope > ion-tabs"
  );
  if (ionPage) {
    return ionPage;
  }
  // idk, return the original element so at least something animates and we don't have a null pointer
  return element;
};

export const mdTransitionAnimation = (_: HTMLElement, opts: any): Animation => {
  const OFF_BOTTOM = "40px";
  const CENTER = "0px";

  const backDirection = opts.direction === "back";
  const enteringEl = opts.enteringEl;
  const leavingEl = opts.leavingEl;

  const ionPageElement = getIonPageElement(enteringEl);
  const enteringToolbarEle = ionPageElement.querySelector("ion-toolbar");
  const rootTransition = createAnimation();

  rootTransition
    .addElement(ionPageElement)
    .fill("both")
    .beforeRemoveClass("ion-page-invisible");

  // animate the component itself
  if (backDirection) {
    rootTransition
      .duration(opts.duration || 200)
      .easing("cubic-bezier(0.47,0,0.745,0.715)");
  } else {
    rootTransition
      .duration(opts.duration || 280)
      .easing("cubic-bezier(0.36,0.66,0.04,1)")
      .fromTo("transform", `translateY(${OFF_BOTTOM})`, `translateY(${CENTER})`)
      .fromTo("opacity", 0.01, 1);
  }

  // Animate toolbar if it's there
  if (enteringToolbarEle) {
    const enteringToolBar = createAnimation();
    enteringToolBar.addElement(enteringToolbarEle);
    rootTransition.addAnimation(enteringToolBar);
  }

  // setup leaving view
  if (leavingEl && backDirection) {
    // leaving content
    rootTransition
      .duration(opts.duration || 200)
      .easing("cubic-bezier(0.47,0,0.745,0.715)");

    const leavingPage = createAnimation();
    leavingPage
      .addElement(getIonPageElement(leavingEl))
      .onFinish(currentStep => {
        if (currentStep === 1 && leavingPage.elements.length > 0) {
          leavingPage.elements[0].style.setProperty("display", "none");
        }
      })
      .afterStyles({ display: "none" })
      .fromTo("transform", `translateY(${CENTER})`, `translateY(${OFF_BOTTOM})`)
      .fromTo("opacity", 1, 0);

    rootTransition.addAnimation(leavingPage);
  }

  return rootTransition;
};
