import React, { useEffect, useRef } from 'react';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { useIonViewDidEnter } from '@ionic/react';
import { ProductWidget } from './SnapshotProducts';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100
});

export default function StoreCart({ cart, onDetail }: any) {
  const items = (cart && Object.values(cart)) || [];
  const listRef = useRef<any>(null);

  useEffect(() => {
    cache.clearAll();
  }, [items]);

  useIonViewDidEnter(() => {
    if (listRef && listRef.current && items && items.length) {
      cache.clearAll();
      listRef.current.forceUpdateGrid();
    }
  });

  if (!cart) {
    return null;
  }
  const rowRenderer = ({ index, isScrolling, parent, style }: any) => {
    const datum: any = items[index];
    return (
      <CellMeasurer rowIndex={index} columnIndex={0} parent={parent} cache={cache} key={datum.id}>
        <ProductWidget onDetail={onDetail} rowOnly={true} style={style} data={datum}></ProductWidget>
      </CellMeasurer>
    );
  };

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <List
            className="app-grid"
            ref={listRef}
            height={height}
            rowCount={items.length}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            scrollToIndex={0}
            width={width}
          />
        );
      }}
    </AutoSizer>
  );
}
