import React from "react";
import "./Category.css";
import { IonLabel } from "@ionic/react";

export default function CategoryBox({ category, selected, onClick }: any) {
  return (
    <div
      className={`category-box ${selected ? "selected" : ""}`}
      onClick={onClick}
    >
      <IonLabel color={selected ? "light" : "primary"}>
        {category.name}
      </IonLabel>
    </div>
  );
}
