import React, { useEffect, useState } from 'react';
import { IonContent, IonPage, IonToolbar, IonHeader, useIonViewDidEnter } from '@ionic/react';
import Header from './StoreHeader';
import StoreListing from './StoreListing';
import CategoriesSlider from './CategoryFilter/Categories';
import ShopSelector from './ShopSelector';
import { useSelector, useDispatch } from 'react-redux';
import { setStore } from '../redux/cartReducer';
import { v4 } from 'uuid';
import StoreFooter from './StoreFooter';
import { getDomainPath } from '../Main/customDomain';
import { Helmet } from 'react-helmet';

const SupplierStore: React.FC = ({ history }: any) => {
  const dispatch = useDispatch();
  const showSearchBox = useSelector(({ cart }: any) => cart.showSearchBox);
  const filters = useSelector(({ cart }: any) => cart.filters);
  const [shopHash, setShopHash] = useState<any>(null);
  const supplierStore = useSelector(({ cart }: any) => cart.supplierStore);
  const shop = useSelector(({ cart }: any) => cart.shop);
  const listing = (supplierStore && supplierStore.listing) || [];
  const [showCities, setShowCities] = useState<any>(false);

  useEffect(() => {
    if (!shop && supplierStore && supplierStore.shops) {
      setShowCities(true);
    }
  }, [shop, supplierStore]);

  useIonViewDidEnter(() => {
    setShopHash(v4());
  });

  const onDetail = (product: any) => {
    const { path } = getDomainPath(supplierStore.shortName);
    history.push({
      pathname: `${path}store/product/${product.id}`,
      search: `?shop=${shop.id}`
    });
  };

  const onShopSelection = (shop: any) => {
    setShowCities(false);
    dispatch(setStore(supplierStore, shop));
    history.replace({
      pathname: history.location.pathname,
      search: `?shop=${shop.id}`
    });
    window.location.reload();
  };
  if (!supplierStore) {
    return null;
  }

  return (
    <IonPage>
      {supplierStore ? (
        <Helmet>
          <title>{supplierStore.name}</title>
          <meta name="description" content={supplierStore.description} />
          <meta name="keywords" content={`${supplierStore.name},pedidos online`} />
          <meta property="og:site_name" content={supplierStore.name} />
          <meta property="og:title" content={supplierStore.name} />
          <meta property="og:description" content={supplierStore.description || supplierStore.name} />
          <meta property="og:secure_url" data-itemprop="image" content={supplierStore.imageUrl} />
          <meta property="og:image" data-itemprop="image" content={supplierStore.imageUrl} />
          <meta property="og:type" content="website" />
          <meta property="og:updated_time" content="1440432930" />
        </Helmet>
      ) : null}
      <Header
        openShopSelection={() => {
          setShowCities(true);
        }}
      />
      <IonToolbar className={`category-header ion-padding-start ion-padding-end ${(!showSearchBox && 'small') || ''}`}>
        {/* {shopHash ? ( */}
        <CategoriesSlider
          defaultSearch={filters.search}
          searchMode={showSearchBox}
          key={shopHash}
          id={shopHash}
          shopId={shop && shop.id}
          categories={listing}
        />
        {/* ) : null} */}
      </IonToolbar>
      <IonContent forceOverscroll={false} scrollY={false} className="body-container">
        <div className="shadow-container">
          <ShopSelector isOpen={showCities} onSelect={onShopSelection} shops={supplierStore.shops} />
          <StoreListing onDetail={onDetail} listing={listing} />
        </div>
      </IonContent>
      <StoreFooter />
    </IonPage>
  );
};

export default SupplierStore;
