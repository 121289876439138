import React, { forwardRef } from "react";
import { IonLabel } from "@ionic/react";
import { IonContent, IonButton, IonIcon } from "@ionic/react";
import { logoWhatsapp } from "ionicons/icons";

function OrderConfirmation(
  { order, store, onShare, link, whatsapp }: any,
  ref: any
) {
  const parts = order && order.split("/");

  return (
    <div className="final-page">
      <IonContent className="ion-padding ion-text-center">
        <img alt="logo" src={store.imageUrl} />
        <div className="elevated-box">
          <div className="elevated-container ion-text-center order-ticket">
            <IonLabel color="primary">
              Tu pedido en {store.name} fue generado
            </IonLabel>
          </div>
        </div>
        <div className="elevated-box">
          <div className="elevated-container ion-text-center order-ticket">
            <IonLabel color="primary">
              Confirma tu orden para finalizar
            </IonLabel>
          </div>
        </div>
        <IonButton
          onClick={() => {
            onShare();
          }}
          size="large"
          expand="block"
          color="success"
        >
          <IonLabel>{"Confirmar por Whatsapp"} </IonLabel>
          <IonIcon color="success" icon={logoWhatsapp} />
        </IonButton>
      </IonContent>
    </div>
  );
}

export default forwardRef(OrderConfirmation);
