import * as firebase from 'firebase/app';
import 'firebase/auth';

export function anonymousAuth() {
  if (!firebase.auth().currentUser) {
    return firebase
      .auth()
      .signInAnonymously()
      .then(res => {
        return res.user;
      });
  }
  return firebase.auth().currentUser;
}
