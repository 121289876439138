import React from 'react';
import { IonLabel } from '@ionic/react';

export default function ProductOptionsResume({ inCart, options }: any) {
  const selections = inCart && inCart.selections;
  const comments = inCart && inCart.comments;

  if (!selections && !inCart && comments) {
    return null;
  }

  const selectionLength = selections && Object.keys(selections).length;
  const missingQty = ((inCart && inCart.qty) || 0) - (selectionLength ? selectionLength : 0);
  let missing = inCart && options && selectionLength !== inCart.qty ? `Faltan detalles para ${missingQty}` : '';
  let concat = '';

  if (selections) {
    Object.values(selections).forEach((it: any) => {
      const { id, ...se } = it;
      Object.values(se).forEach((sel: any) => {
        const { value } = sel;
        if (value && value.length) {
          const fullVal = value.reduce((full: any, av: any) => full + av.name + ',', '');
          concat += `${fullVal} `;
        } else if (value) {
          concat += `${value.name},`;
        }
      });
    });
  }

  return (
    <>
      {missing ? (
        <IonLabel color="warning" className="product-options-resume">
          {missing}{' '}
        </IonLabel>
      ) : null}

      <IonLabel className="product-options-resume">
        {concat} {comments}
      </IonLabel>
    </>
  );
}
