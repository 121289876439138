import React, { useRef } from 'react';
import _ from 'lodash';
import { IonLabel, IonSelect, IonIcon, IonSelectOption, IonItem } from '@ionic/react';
import { warning } from 'ionicons/icons';

export default function ProductSelection({ option, optionSelection, setOptionSelection }: any) {
  const selectRef = useRef<any>(null);
  debugger;
  const items = _.sortBy(Object.values(option.values || {}), 'sequence');
  const opType = option.type && option.type.value;
  const max = option.max;
  const customAlertOptions = {
    header: `Seleccionar ${option.name}`,
    message: `${opType === 'multi' && max ? `Máximo ${option.max}` : ''}`,
    translucent: true
  };

  return (
    <IonItem>
      <IonLabel color="primary" className="ion-display-flex ion-align-items-center">
        {!optionSelection ? <IonIcon color="warning" icon={warning} /> : null}
        {option.name}
      </IonLabel>
      <IonSelect
        ref={selectRef}
        placeholder={option.name}
        compareWith={(e1: any, e2: any) => {
          return e1 && e2 && JSON.stringify(e1) === JSON.stringify(e2);
        }}
        value={optionSelection || null}
        interfaceOptions={customAlertOptions}
        multiple={opType === 'multi'}
        onIonChange={e => {
          if (e.detail.value && opType === 'multi' && max && e.detail.value.length > max) {
            alert(`Por favor seleccionar sólo ${max} opciones`);
            e.preventDefault();
            if (selectRef && selectRef.current) {
              selectRef.current.value = _.take(e.detail.value, max);
            }
            return false;
          }
          setOptionSelection(e.detail.value);
        }}
      >
        {items.map((it: any) => (
          <IonSelectOption key={it.id} value={it}>
            {it.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
}
