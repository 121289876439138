import React, { useRef } from 'react';
import { IonItem, IonInput, IonLabel, IonIcon } from '@ionic/react';
import WarnIcon from './WarnIcon';
import bolivia from '../../assets/images/bolivia.svg';
import './ElevatedInput.css';

const ElevatedInput = ({
  type,
  onChange,
  onInput,
  onBlur,
  onRemove,
  value,
  label,
  id,
  meta,
  loading,
  ...params
}: any) => {
  let inputRef = useRef<any>();
  const error = meta && meta.touched && meta.error;
  const focusInput = () => {
    if (inputRef && inputRef.current !== null) {
      inputRef.current.setFocus();
    }
  };
  return (
    <div className="elevated-box">
      <div className="elevated-container">
        <IonItem className={`elevated-item ${error ? 'ion-invalid' : ''}`} onClick={focusInput}>
          {params.area && params.area === 'bo' ? <IonIcon icon={bolivia} /> : null}
          <IonInput
            {...params}
            id={id}
            name={id}
            maxlength={params.area ? 8 : undefined}
            onIonBlur={onBlur}
            className="elevated-input"
            ref={inputRef}
            placeholder={label}
            defaultValue={''}
            onIonChange={onChange}
            onIonInput={onInput}
            value={value}
            type={type}
          />

          {value ? (
            <IonLabel className="elevated-label" color="secondary" slot="end">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {label}
                {error && !loading ? <WarnIcon onClick={focusInput} /> : null}
              </div>
            </IonLabel>
          ) : null}
        </IonItem>

        {error || onRemove ? (
          <div className="elevated-error">
            {error}
            {onRemove ? (
              <IonLabel color="warning" onClick={onRemove}>
                Eliminar
              </IonLabel>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ElevatedInput;
