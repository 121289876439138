import React from "react";
import { useField } from "formik";
import ElevatedInput from "./ElevatedInput";
import ElevatedPhone from "./ElevatedPhone";
import ElevatedTextarea from "./ElevatedTextarea";
import ElevatedToggle from "./ElevatedToggle";

const FormInput = ({ id, type, customBlur, onChange, ...props }: any) => {
  const [{ value, checked }, meta, { setValue, setTouched }] = useField({
    id,
    name: id,
    type: type
  });
  const asCheckbox = type === "checkbox" || false;
  const IonChange = (e: any) => {
    let { value, checked } = e.detail;
    if (type === "checkbox") {
      value = checked;
    }
    if (onChange) {
      onChange(asCheckbox ? value : value || "");
    }
    setValue(asCheckbox ? value : value || "");
  };

  const IonBlur = (e: any) => {
    setTouched(true);
    customBlur && customBlur();
  };

  let Component;
  switch (type) {
    case "checkbox":
      Component = ElevatedToggle;
      break;
    case "textarea":
      Component = ElevatedTextarea;
      break;
    case "tel":
      Component = ElevatedPhone;
      break;
    default:
      Component = ElevatedInput;
      break;
  }
  return (
    <Component
      type={type}
      id={id}
      onChange={IonChange}
      onBlur={IonBlur}
      value={value}
      checked={checked}
      {...props}
      meta={meta}
    />
  );
};

export default FormInput;
