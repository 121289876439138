import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import AppForm from '../../../components/form/AppForm';
import FormInput from '../../../components/form/FormInput';
import { useSelector, useDispatch } from 'react-redux';
import BranchLocationPickup from '../../../components/BranchLocationPickup';
import * as Yup from 'yup';
import DeliveryPrice from './DeliveryPrice';
import FormDropdown from '../../../components/form/FormDropdown';
import { setDeliveryPrice } from '../redux/cartReducer';
import FormDate from '../../../components/form/FormDate';
import moment from 'moment';
import FormTimeSpan from './FormTimeSpan';
function PickingDetail({ onSubmit, data }: any, ref: any) {
  const supplierStore = useSelector(({ cart }: any) => cart.supplierStore);
  const dispatch = useDispatch();
  const defaultData: any = useSelector(({ cart }: any) => data || cart.picking);
  const shop = useSelector(({ cart }: any) => cart.shop);
  const branches = (supplierStore && supplierStore.branches && supplierStore.branches.branches) || [];
  const branch = branches.find((b: any) => {
    return b.id === shop.description;
  });
  const deliveryOptions = [
    { value: 'delivery', string: 'A domicilio' },
    { value: 'pickup', string: `Recoger en ${(branch && branch.address) || 'Sucursal'}` }
  ];

  const scheduleOptions = [
    { value: 'inmediate', string: 'Lo antes posible' },
    { value: 'future', string: `Programada` }
  ];

  const [deliveryType, setDeliveryType] = useState<any>(deliveryOptions[0]);
  const [scheduleType, setScheduleType] = useState<any>(scheduleOptions[0]);
  const [deliveryDate, setDeliveryDate] = useState<any>(moment());
  const [address, setAddress] = useState<any>('');
  const formRef = useRef<any>(null);
  const picking: any = useSelector(({ cart }: any) => cart.picking);
  const price = useSelector(({ cart }: any) => cart.deliveryPrice);
  const [latlng, setLatlng] = useState(null);
  let initialValues = { deliveryPrice: null, type: deliveryType, schedule: scheduleType, deliveryDate };
  const validationSchema = Yup.object({
    name: Yup.string().required('Ingresa tu nombre'),
    mobile: Yup.string().required('Ingresa un contacto de Whatsapp para tus pedidos'),
    schedule: Yup.object().required('Selecciona cuando quieres la entrega'),
    type: Yup.object().required('Selecciona el tipo de entrega'),
    address:
      deliveryType && deliveryType.value === 'delivery'
        ? Yup.string().required('Ingresa una dirección de envío')
        : Yup.string().nullable(),
    location:
      deliveryType && deliveryType.value === 'delivery'
        ? Yup.object().required('Debes seleccionar una ubicación de envío')
        : Yup.string().nullable()
  });

  useEffect(() => {
    if (defaultData) {
      if (formRef && formRef.current) {
        const { type, schedule, name, mobile, address, location, deliveryPrice, deliveryDate } = defaultData;
        const {
          type: ftype,
          schedule: fschedule,
          name: fname,
          mobile: fmobile,
          address: faddress,
          location: flocation,
          deliveryPrice: fdeliveryPrice,
          deliveryDate: fdeliveryDate
        } = formRef.current.getForm().values;
        formRef.current.getForm().setValues({
          type: type || ftype,
          schedule: schedule || fschedule,
          name: name || fname,
          mobile: mobile || fmobile,
          address: address || faddress,
          location: location || flocation,
          deliveryPrice: deliveryPrice || fdeliveryPrice,
          deliveryDate: deliveryDate || fdeliveryDate
        });
        if (defaultData.type) {
          setDeliveryType(defaultData.type);
        }

        if (defaultData.schedule) {
          setScheduleType(defaultData.schedule);
        }
        if (defaultData.deliveryDate) {
          setDeliveryDate(defaultData.deliveryDate);
        }
      }
    }
  }, [JSON.stringify(defaultData)]);

  const setDelivery = (zone: any) => {
    const values = formRef.current.getForm().values;
    formRef.current.getForm().setValues({ ...values, deliveryPrice: zone });
    dispatch(setDeliveryPrice(zone));
  };

  useImperativeHandle(ref, () => {
    return {
      submit: () => {
        if (formRef.current) {
          const values = formRef.current.getForm().values;
          if (values.type === 'delivery' && !values.location) {
            return alert('Debe seleccionar su ubicación para continuar');
          }
          formRef.current.submitForm();
        }
      }
    };
  });

  const onLocationPickup = (latlng: any, address: string) => {
    if (formRef && formRef.current) {
      const formController = formRef.current.getForm();
      formController.setValues({ ...formController.values, location: latlng, address });
      formController.setFieldTouched('location', true);
      setLatlng(latlng);
      setAddress(address);
    }
  };

  return (
    <AppForm
      validationSchema={validationSchema}
      initialValues={initialValues}
      ref={formRef}
      onSubmit={onSubmit}
      disableReinitialize={true}
    >
      <FormInput autocapitalize={true} id="name" type="text" label="Nombre" />
      <FormInput inputmode="tel" id="mobile" type="tel" label="Celular" />
      {/* <FormInput autocapitalize={true} id="detail" label="Detalles adicionales"></FormInput> */}

      <FormDropdown
        onChange={(v: any) => setScheduleType(v)}
        id="schedule"
        items={scheduleOptions}
        itemKey="value"
        itemLabel="string"
        label="Entregar"
      ></FormDropdown>

      {scheduleType && scheduleType.value === 'future' ? (
        <>
          {supplierStore && supplierStore.enableProgrammableOrders ? (
            <FormDate
              onChange={(date: any) => setDeliveryDate(date)}
              id="deliveryDate"
              label="Fecha de entrega"
            ></FormDate>
          ) : null}

          {branch && branch.deliveryTimes ? (
            <FormTimeSpan
              id="deliveryTime"
              label="Hora de entrega"
              branch={branch}
              deliveryDate={deliveryDate}
            ></FormTimeSpan>
          ) : null}
        </>
      ) : null}
      <FormDropdown
        onChange={(v: any) => setDeliveryType(v)}
        id="type"
        items={deliveryOptions}
        itemKey="value"
        itemLabel="string"
        label="Tipo de Entrega"
      ></FormDropdown>
      {deliveryType && deliveryType.value === 'delivery' ? (
        <>
          <BranchLocationPickup
            simpleView={true}
            latlng={latlng || picking.location || null}
            onSelect={onLocationPickup}
            address={address}
            zones={shop && shop.zones}
            setDeliveryPrice={setDelivery}
          />
          <FormInput autocapitalize={true} id="address" type="textarea" label="Dirección" />
          <DeliveryPrice id="deliveryPrice" zone={price}></DeliveryPrice>
        </>
      ) : null}
    </AppForm>
  );
}

export default forwardRef(PickingDetail);
