import React from 'react';
import { IonButton, IonInput, IonIcon, IonLabel } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { cartOutline, remove, add } from 'ionicons/icons';
import { addItemToCart } from '../redux/cartReducer';
import moment from 'moment';

export default function ProductAddButton({ inCart, product, onDetail, noJump }: any) {
  const dispatch = useDispatch();
  const flags = product && product.flags;
  const options = product && product.options;
  const { noStockTimestamp, noStockInterval } = flags;
  let noStockLabel = '';
  switch (noStockInterval) {
    case 0:
      noStockLabel = '';
      break;
    case 1000:
      noStockLabel = 'Agotado indef';
      break;
    default:
      try {
        const time = moment(noStockTimestamp);
        const finalTime = time.add(noStockInterval, 'hours');
        var duration = moment.duration(finalTime.diff(moment()));
        var hours = duration.asHours();
        if (hours && hours >= 0.5) {
          noStockLabel = `Agotado ${hours.toFixed(1)} hrs`;
        }
      } catch (error) {
        console.error(error);
        noStockLabel = '';
      }
      break;
  }

  return (
    <div className="product-detail-purchase">
      {!inCart ? (
        !noStockLabel ||
        (product.flags.usage === 'product' && parseFloat(product.flags.totalQty) > 0 && !noStockLabel) ? (
          <IonButton
            className="listing-button-add"
            onClick={(e: any) => {
              dispatch(addItemToCart(product));
              e.preventDefault();
              e.stopPropagation();
              if (options && onDetail && !noJump) {
                onDetail(product);
              }
              return false;
            }}
            color="primary"
            size="small"
          >
            <IonIcon icon={cartOutline} />
            <IonLabel>Ordenar</IonLabel>
          </IonButton>
        ) : (
          <IonLabel className="listing-product-outos" color="danger">
            Agotado
          </IonLabel>
        )
      ) : (
        <div className="listing-qty-container">
          {(parseInt(product.maxPerOrder) >= 0 && inCart.qty >= parseInt(product.maxPerOrder)) ||
          (product.flags.usage === 'product' && inCart.qty >= parseFloat(product.flags.totalQty)) ? (
            <div className="qty-error">Máximo de {product.maxPerOrder || product.flags.totalQty} alcanzado</div>
          ) : null}
          <div className="listing-qty">
            <IonButton
              size="small"
              className="listing-button-qty"
              onClick={(e: any) => {
                dispatch(addItemToCart(product, inCart.qty - 1));
                e.preventDefault();
                e.stopPropagation();
                return false;
              }}
              color="primary"
              slot="end"
            >
              <IonIcon icon={remove} />
            </IonButton>
            <IonInput
              className="listing-input"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                if (options && onDetail && !noJump) {
                  onDetail(product);
                }
                return false;
              }}
              onIonChange={(e: any) => {
                const val = parseFloat(e.detail.value);
                if (val && val >= 0) {
                  dispatch(addItemToCart(product, val));
                  return false;
                }
                if (val < 0) {
                  return false;
                }
              }}
              value={inCart.qty}
            />
            <IonButton
              size="small"
              className="listing-button-qty"
              onClick={(e: any) => {
                dispatch(addItemToCart(product, inCart.qty + 1));
                e.preventDefault();
                e.stopPropagation();
                if (options && onDetail && !noJump) {
                  onDetail(product);
                }
                return false;
              }}
              color="primary"
              slot="end"
            >
              <IonIcon icon={add} />
            </IonButton>
          </div>

          {/* <div className="qty-message">en tu carrito</div> */}
        </div>
      )}
    </div>
  );
}
