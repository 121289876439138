import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './saga';
import mainReducer from './reducer';

// Store
const sagaMiddleware = createSagaMiddleware();

export const store = createStore(mainReducer, compose(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
