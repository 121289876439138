import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import AppForm from '../../../components/form/AppForm';
import FormInput from '../../../components/form/FormInput';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import FormRadioSelect from '../../../components/form/FormRadioSelect';
import FormDropdown from '../../../components/form/FormDropdown';

function DetailDetail({ onSubmit, data }: any, ref: any) {
  const formRef = useRef<any>(null);
  const supplierStore = useSelector(({ cart }: any) => cart.supplierStore);
  const defaultData: any = useSelector(({ cart }: any) => data || cart.picking);
  let initialValues = {};
  const [payType, setPayType] = useState<any>(null);
  const validationSchema = Yup.object({
    payType: Yup.string().required('Selecciona un método de pago'),
    invoiceName:
      (supplierStore.invoices && Yup.string().required('Introduce el nombre para tu factura')) ||
      Yup.string().nullable(),
    nit:
      (supplierStore.invoices && Yup.number().required('Introduce el nit para tu factura')) || Yup.string().nullable()
  });

  useEffect(() => {
    if (defaultData) {
      if (formRef && formRef.current) {
        formRef.current.getForm().setValues(defaultData);
        setPayType(defaultData.payType);
      }
    }
  }, [defaultData]);

  useImperativeHandle(ref, () => {
    return {
      submit: () => {
        formRef.current && formRef.current.submitForm();
      }
    };
  });

  const paymentTypes = [];
  if (supplierStore.cashPayment) {
    paymentTypes.push({ value: 'cash', string: 'Efectivo contra entrega' });
  }

  if (supplierStore.transferPayment) {
    paymentTypes.push({ value: 'transfer', string: 'Transferencia bancaria' });
  }

  return (
    <AppForm validationSchema={validationSchema} initialValues={initialValues} ref={formRef} onSubmit={onSubmit}>
      {supplierStore.invoices ? (
        <>
          <FormInput autocapitalize={true} id="invoiceName" type="text" label="Factuar a:" />
          <FormInput inputmode="tel" id="nit" type="number" label="Nit / C.I" />}
        </>
      ) : null}
      {supplierStore && (supplierStore.cashPayment || supplierStore.transferPayment) ? (
        <FormDropdown
          id="payType"
          onChange={(v: any) => setPayType(v)}
          label="Forma de pago"
          items={paymentTypes}
          itemKey="value"
          itemValue="string"
        ></FormDropdown>
      ) : null}
      {payType === 'transfer' && supplierStore.transferUrl ? (
        <img className="transfer-account" alt="transfer" src={supplierStore.transferUrl} />
      ) : null}
    </AppForm>
  );
}

export default forwardRef(DetailDetail);
