import { useState, useEffect, useCallback } from "react";
import { fetchDocument } from "../services/fetch";

export function useProductDetail(id: string, params: any): any {
  const collection = "ProductDetail";
  const [document, setDocument] = useState(null);
  const [state, setState] = useState("none");
  const paramsHash = JSON.stringify(params);

  const fetchItem = useCallback(async () => {
    if (state === "fetching") {
      return;
    }
    setState("fetching");
    try {
      const data = await fetchDocument(
        collection,
        id,
        paramsHash && JSON.parse(paramsHash)
      );
      if (data) {
        setDocument(data);
      }
      setState("ready");
    } catch (error) {
      console.error(error);
      setState("error");
    }
  }, [id, collection, paramsHash, setDocument, setState]);

  useEffect(() => {
    fetchItem();
  }, [fetchItem, paramsHash, id]);

  return [document, state];
}
