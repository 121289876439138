import { useCallback, useState, useEffect } from 'react';
import { useAuthorizedRequest } from './useFirebaseAuthorizedRequest';
import { retriveCurrentCartFor, retriveCartCopy } from '../services/shopping';
import { useSelector, useDispatch } from 'react-redux';
import { setShoppingCart, setLoadingState } from '../../redux/cartReducer';
import { useIonViewWillEnter } from '@ionic/react';

let fetchingState = 'none';
export default function useShoppingCart(supplierId: string, shopId: number) {
  const dispatch = useDispatch();
  const cart = useSelector(({ cart }: any) => {
    return cart.cart;
  });
  const meta: any = { totalAmount: 0, totalQty: 0 };

  if (cart && supplierId && shopId && cart.products) {
    meta.totalAmount = Object.values(cart.products).reduce((total: number, line: any) => {
      return total + line.qty * line.flags.salePrice;
    }, 0);
    meta.totalQty = Object.values(cart.products).reduce((total: number, line: any) => {
      return total + line.qty;
    }, 0);
  }

  const fetchItem = async (user: any) => {
    if (fetchingState === 'fetching') {
      return;
    }
    fetchingState = 'fetching';
    try {
      dispatch(setLoadingState(true, 'Recuperando carrito'));
      const data = await retriveCurrentCartFor(supplierId, shopId, user.uid);
      dispatch(setLoadingState(false, 'Cargando carrito'));
      if (data) {
        dispatch(setShoppingCart(data));
      }
      fetchingState = 'ready';
    } catch (error) {
      dispatch(setLoadingState(false, 'Cargando carrito'));
      console.error(error);
      fetchingState = 'error';
    }
  };

  useAuthorizedRequest(fetchItem, [supplierId, shopId]);

  return [cart, fetchingState, meta];
}

export function useShoppingCartCopy(cartId: number) {
  const dispatch = useDispatch();
  const [cart, setCart] = useState<any>(null);
  const [fetchingState, setState] = useState('none');
  const meta: any = { totalAmount: 0, totalQty: 0 };

  if (cart && cart.products) {
    meta.totalAmount = Object.values(cart.products).reduce((total: number, line: any) => {
      return total + line.qty * line.flags.salePrice;
    }, 0);
    meta.totalQty = Object.keys(cart.products).length;
  }

  const fetchItem = async () => {
    if (fetchingState === 'fetching') {
      return;
    }
    setState('fetching');
    try {
      const data = await retriveCartCopy(cartId);
      if (data) {
        setCart(data);
      }
      setState('ready');
    } catch (error) {
      console.error(error);
      setState('error');
    }
  };

  useEffect(() => {
    if (cartId) {
      fetchItem();
    }
  }, [cartId]);

  return [cart, fetchingState, meta];
}
