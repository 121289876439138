import React from 'react';
import { useField } from 'formik';
import ElevatedDropdown from './ElevatedDropdown';

export default function FormDropdown({ id, onChange, ...props }: any) {
  const [{ value }, meta, { setValue }] = useField({ id, name: id, type: 'select' });
  const IonChange = (val: any) => {
    setValue(val);
    if (onChange && val) {
      onChange(val);
    }
  };
  return <ElevatedDropdown id={id} onChange={IonChange} value={value} {...props} meta={meta}></ElevatedDropdown>;
}
