import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import ElevatedDropdown from '../../../components/form/ElevatedDropdown';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setError } from '../redux/cartReducer';

const DAY_MAP: any = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat'
};

export default function FormTimeSpan({ id, onChange, branch, label, deliveryDate, ...props }: any) {
  const [{ value }, meta, { setValue }] = useField({ id, name: id, type: 'select' });
  const dispatch = useDispatch();
  const [items, setItems] = useState<any>([]);
  const IonChange = (val: any) => {
    setValue(val);
  };

  useEffect(() => {
    const day = deliveryDate && deliveryDate.get('day');
    const times = branch.times;
    const timeConfig = times[DAY_MAP[day]];
    if (timeConfig) {
      const interval = timeConfig.times && timeConfig.times[0];
      const intervals = [];
      let { start, end } = interval;
      start = moment(start);
      end = moment(end);
      let begin = deliveryDate.clone().hour(start.get('hour'));
      begin.minute(start.get('minute'));
      begin.startOf('minute');
      let finish = deliveryDate.clone().hour(end.get('hour'));
      finish.minute(end.get('minute'));
      finish.startOf('minute');
      while (begin < finish) {
        const aux = begin.clone().add(1, 'hour');
        if (begin >= moment()) {
          const name = `~ ${begin.format('HH:mm')} - ${aux.format('HH:mm')}`;
          intervals.push({ from: begin, to: aux, key: `${begin.toJSON()}${aux.toJSON()}`, name });
        }
        begin = aux;
      }
      if (!intervals.length) {
        setItems([{ name: 'Fuera de hora', key: 'Not found' }]);
        setValue(intervals[0]);
        dispatch(
          setError({
            message: 'Terminó el periodo de entregas para la fecha seleccionada, puedes reservar para otra fecha'
          })
        );
      } else {
        setItems(intervals);
        setValue(intervals[0]);
      }
      if (onChange) {
        onChange(intervals[0]);
      }
    } else {
      setItems([]);
    }
  }, [deliveryDate, branch]);

  return (
    <ElevatedDropdown
      id="id"
      onChange={IonChange}
      label={label}
      value={value}
      items={items}
      itemKey="key"
      itemValue="name"
      meta={meta}
    ></ElevatedDropdown>
  );
}
