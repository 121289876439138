import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import React, { useState, useCallback } from 'react';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import { IonLabel } from '@ionic/react';
import './ElevatedDatePicker.css';
moment.locale('es');

const renderMonth = (date: any) => {
  const month = moment(date).get('month');
  const year = moment(date).get('year');
  let monthText = '';
  switch (month + 1) {
    case 1:
      monthText = 'Enero';
      break;
    case 2:
      monthText = 'Febrero';
      break;
    case 3:
      monthText = 'Marzo';
      break;
    case 4:
      monthText = 'Abril';
      break;
    case 5:
      monthText = 'Mayo';
      break;
    case 6:
      monthText = 'Junio';
      break;
    case 7:
      monthText = 'Julio';
      break;
    case 8:
      monthText = 'Agosto';
      break;
    case 9:
      monthText = 'Septiembre';
      break;
    case 10:
      monthText = 'Octubre';
      break;
    case 11:
      monthText = 'Noviembre';
      break;
    case 12:
      monthText = 'Diciembre';
      break;
    default:
      monthText = '';
  }
  return `${monthText} ${year}`;
};

export default function ElevatedDatePicker({ label, id, onChange, date, meta }: any) {
  const [focused, setFocus] = useState(false);
  return (
    <div className="elevated-box">
      <div className="elevated-container">
        <div className={`elevated-item`}>
          <div className="date-picker-box elevated-subcontainer">
            <SingleDatePicker
              verticalHeight={400}
              withPortal={true}
              numberOfMonths={1}
              monthFormat={'MMMM YYYY'}
              displayFormat={'DD/MM/YYYY'}
              renderMonthText={renderMonth}
              id={id}
              placeholder={label}
              date={date}
              showClearDate
              block
              keepOpenOnDateSelect={false}
              focused={focused}
              onFocusChange={(e: any) => {
                setFocus(e.focused);
              }}
              onDateChange={val => onChange(val)}
            ></SingleDatePicker>
            <IonLabel color="primary">{label}</IonLabel>
          </div>
          {meta.error ? <IonLabel color="warning"> {meta.error}</IonLabel> : null}
        </div>
      </div>
    </div>
  );
}
