import React from 'react';
import { useField } from 'formik';
import ElevatedDatePicker from '../ElevatedDatePicker/ElevatedDatePicker';

export default function FormDate({ id, onChange, ...props }: any) {
  const [{ value }, meta, { setValue }] = useField({ id, name: id, type: 'select' });
  const IonChange = (val: any) => {
    setValue(val);
    if (onChange) {
      onChange(val);
    }
  };
  return <ElevatedDatePicker id={id} onChange={IonChange} date={value} {...props} meta={meta}></ElevatedDatePicker>;
}
