import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router';
import { useDocument } from '../services/hooks/useDocument';
import { useDispatch } from 'react-redux';
import { setStore } from '../redux/cartReducer';

export default function useLoadApp() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const dispatch = useDispatch();
  const query = useQuery();
  const queryShop: any = query.get('shop');
  const params: any = useParams();

  const [supplierStore, supplierStoreState] = useDocument('Stores', params.id, {
    shopId: parseInt(queryShop)
  });
  const storeId = supplierStore && supplierStore.id;
  const shop =
    supplierStore &&
    supplierStore.shops &&
    queryShop &&
    supplierStore.shops.find((s: any) => s.id === parseInt(queryShop));

  useEffect(() => {
    if (storeId) {
      dispatch(setStore(supplierStore, shop));
    }
  }, [storeId, shop]);
}
