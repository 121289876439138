import React, { useEffect } from 'react';
import { setupConfig, IonApp, IonAlert, IonLoading, IonRouterOutlet } from '@ionic/react';
import { Route, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import MarketPlace from '../MarketPlace/MarketPlaceHome';
import { mdTransitionAnimation } from '../../../page-transition';
import './Main.css';
import StoreAccess from './StoreAccess';
import { isCustomDomain } from './customDomain';

const Main: React.FC = () => {
  useEffect(() => {
    setupConfig({
      rippleEffect: false,
      mode: 'ios',
      navAnimation: mdTransitionAnimation
    });
  }, []);

  return (
    <IonReactRouter>
      <IonRouterOutlet>
        {isCustomDomain() ? (
          <Route path="/" component={StoreAccess} />
        ) : (
          <>
            <Route path="/" component={MarketPlace} exact={true} />
            <Route path="/:id" component={StoreAccess} />
          </>
        )}
      </IonRouterOutlet>
    </IonReactRouter>
  );
};

export default Main;
