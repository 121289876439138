import axios from "axios";
import "./auth";

export function fetchCollection(collection: string, query: any = {}) {
  const defaultPagination = { limit: 30, offset: 0 };
  const pagination = query.pagination || defaultPagination;
  return axios
    .get(`/collection/${collection}`, {
      params: {
        search: query.search,
        limit: pagination.limit,
        offset: pagination.offset,
        attributes: query.attributes,
        raw: true,
        sortBy: query.sortBy
      }
    })
    .then(res => res.data);
}

export function fetchDocument(collection: string, id: string, params: any) {
  return axios
    .get(`/document/${collection}/${id}`, { params: params })
    .then(res => res.data);
}
