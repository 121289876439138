import React from 'react';
var precision = 5;
var factor = Math.pow(10, precision);

export function MoneyDisplay({ value, forceDigits = 2, plainNumber = false }: any) {
  var sing = '';
  if (value < 0) {
    sing = '-';
  }
  value = Math.abs(value);
  var factor = Math.pow(10, precision);
  var val = (value || 0) / factor;
  var digit_parts = val.toString().split('.');
  var numericPart: any = Math.floor(val) || 0;
  var decimalPart = val - numericPart;
  // var clearDigits = decimalPart.toString().length - 2;
  var clearDigits = forceDigits;
  forceDigits = clearDigits > 2 ? (clearDigits < precision ? clearDigits : precision) : forceDigits;
  let digits;
  if (forceDigits > 2 && decimalPart && digit_parts.length > 1) {
    digits = digit_parts[digit_parts.length - 1].length;
    digits = (digits >= 5 && 5) || digits;
    digits = (digits < 2 && 2) || digits;
  }
  var fixedValue = val.toFixed(digits ? digits : forceDigits).split('.');
  var fixedDecimal = fixedValue[fixedValue.length - 1] || '0';
  numericPart = fixedValue[0] || numericPart;
  numericPart = parseInt(numericPart);
  if (!plainNumber) {
    numericPart = numericPart.toLocaleString('en-US');
  } else {
    numericPart = numericPart.toString();
  }
  return <>{sing + numericPart + '.' + fixedDecimal}</>;
}
