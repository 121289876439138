import { useState } from 'react';
import { useAuthorizedRequest } from './useFirebaseAuthorizedRequest';
import { retrivePickingDetails } from '../services/shopping';
import { useSelector, useDispatch } from 'react-redux';
import { setPickingDetails } from '../../redux/cartReducer';

let fetchingState = 'none';
export default function usePickingDetails() {
  const dispatch = useDispatch();
  const picking = useSelector(({ cart }: any) => {
    return cart.picking;
  });

  const fetchPickingDetails = async (user: any) => {
    if (fetchingState === 'fetching') {
      return;
    }
    fetchingState = 'fetching';
    try {
      const data = await retrivePickingDetails(user.uid);
      if (data) {
        dispatch(setPickingDetails(data));
      }
      fetchingState = 'ready';
    } catch (error) {
      fetchingState = 'error';
    }
  };

  useAuthorizedRequest(fetchPickingDetails, []);

  return [picking];
}
