import axios from 'axios';
import * as firebase from 'firebase/app';
import 'firebase/auth';
axios.interceptors.request.use(
  async config => {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return config;
    }
    const idToken = await currentUser.getIdToken();
    /* eslint-disable no-param-reassign */
    if (idToken) {
      config.headers.UserAuthorization = `FIREBASE ${idToken}`;
    }
    return config;
  },
  error => {
    // console.warn(error);
    Promise.reject(error);
  }
);
// axios.defaults.baseURL = 'http://172.20.10.6:8080/api/v1/public';
axios.defaults.baseURL = 'https://api.abasto.app/api/v1/public';
