import React from "react";
import { IonInput, IonButton, IonIcon } from "@ionic/react";
import { searchOutline } from "ionicons/icons";
import "./ElevatedSearchInput.css";

export default function ElevatedSearchInput({ onSearch, ...props }: any) {
  return (
    <div className="elevated-search-box">
      <div className="elevated-search-container">
        <IonInput {...props} clearOnEdit={true} clearInput color="primary" />
        <IonIcon color="primary" icon={searchOutline} />
      </div>
      {/* <IonButton onClick={onSearch} className="search-input-icon">
      </IonButton> */}
    </div>
  );
}
