import React, { useRef, useEffect } from 'react';
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import { flattenCategory, CategoryHeaderWidget } from './SnapshotCategory';
import { ProductWidget } from './SnapshotProducts';
import { Banner } from './Banner';
import { useSelector, useDispatch } from 'react-redux';
import './Listing.css';
import _ from 'lodash';
import { useIonViewDidEnter } from '@ionic/react';
import { setScrollIndex } from '../redux/cartReducer';
const ITEM_WIDTH = 334;

function StoreListing({ listing, onDetail }: any) {
  const dispatch = useDispatch();
  const listRef = useRef<any>(null);
  let itemsPerRow = 1;
  const selectedCategory = useSelector(({ cart }: any) => cart.selectedCategory);
  const scrollIndex = useSelector(({ cart }: any) => cart.scrollIndex);

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 350
  });

  const filters = useSelector(({ cart }: any) => cart.filters);

  const searchReg = new RegExp((filters && filters.search) || '', 'gi');

  let flatlist = _.sortBy(listing, 'sequence')
    .filter((category: any) => !selectedCategory || category.id === selectedCategory.id)
    .map((category: any) => flattenCategory(category))
    .reduce((flat: any[], flattedCategory: any[]) => {
      return [...flat, ...flattedCategory];
    }, [])
    .filter((item: any) => {
      if (!filters || !filters.search) {
        return true;
      }
      const base = item.data.name;
      const matches = base.match(searchReg);
      return !!matches;
    });

  useIonViewDidEnter(() => {
    cache.clearAll();
    if (listRef && listRef.current) {
      listRef.current.forceUpdateGrid();
    }
  });

  useEffect(() => {
    if (listRef && listRef.current) {
      setTimeout(() => {
        listRef && listRef.current && listRef.current.scrollToRow(scrollIndex);
      }, 150);
    }
  });

  return (
    <AutoSizer>
      {({ width, height }) => {
        itemsPerRow = Math.floor(width / ITEM_WIDTH);
        if (itemsPerRow < 1) {
          itemsPerRow = 1;
        }
        const itemMargin = itemsPerRow === 1 ? 0 : Math.floor((width - itemsPerRow * ITEM_WIDTH) / itemsPerRow / 2);
        const data: any = [];
        let cursor = 0;
        if (itemsPerRow) {
          while (cursor < flatlist.length) {
            if (!flatlist[cursor]) {
              cursor += 1;
              continue;
            }
            if (flatlist[cursor].type === 'category' || flatlist[cursor].type === 'banner') {
              data.push(flatlist[cursor]);
              cursor += 1;
              continue;
            } else if (flatlist[cursor].type === 'product') {
              const row = [];
              const maxItems = cursor + itemsPerRow;
              for (var i = cursor; i < maxItems; i++) {
                if (!flatlist[i] || flatlist[i].type === 'category') {
                  break;
                } else {
                  cursor += 1;
                  row.push(flatlist[i]);
                }
              }
              if (row && row.length) {
                data.push({ products: row, type: 'product' });
              } else {
                cursor += 1;
              }
            } else {
              cursor += 1;
            }
          }
        }
        const rowRenderer = ({ index, isScrolling, parent, style }: any) => {
          const datum = data[index];
          let Widget: any = null;
          switch (datum.type) {
            case 'banner':
              Widget = Banner;
              break;
            case 'category':
              Widget = CategoryHeaderWidget;
              break;
            case 'product':
              Widget = ProductWidget;
              break;
            default:
              Widget = <div>Not Found</div>;
          }
          if (datum.type === 'category' || datum.type === 'banner') {
            return (
              <CellMeasurer rowIndex={index} columnIndex={0} parent={parent} cache={cache} key={datum.id || 'banner'}>
                <Widget
                  // onClick={() => onClick(datum)}
                  style={style}
                  data={datum.data}
                />
              </CellMeasurer>
            );
          }

          return (
            <CellMeasurer
              rowIndex={index}
              columnIndex={0}
              parent={parent}
              cache={cache}
              key={datum && datum.products && datum.products[0] && datum.products[0].data.id}
            >
              <div style={style} className="listing-multi-product-container">
                {datum.products.map((p: any) => {
                  return (
                    <Widget
                      margin={itemMargin}
                      key={p.id}
                      onDetail={(...args: any) => {
                        dispatch(setScrollIndex(index));
                        onDetail(...args);
                      }}
                      data={p.data}
                    />
                  );
                })}
              </div>
            </CellMeasurer>
          );
        };

        return (
          <List
            className="app-grid"
            ref={listRef}
            height={height}
            rowCount={data.length}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            width={width}
          />
        );
      }}
    </AutoSizer>
  );
}

export default React.memo(StoreListing);
