import { useState, useEffect, useCallback } from "react";
import { fetchDocument } from "../services/fetch";
import { usePublicRequest } from "./usePublicRequest";
import { useDispatch } from "react-redux";
import { setLoadingState } from "../../redux/cartReducer";

export function useDocument(collection: string, id: string, params: any): any {
  const dispatch = useDispatch();
  const [document, setDocument] = useState([]);
  const [state, setState] = useState("none");
  const paramsHash = JSON.stringify(params);

  const fetchItem = useCallback(async () => {
    if (state === "fetching") {
      return;
    }
    setState("fetching");
    try {
      // dispatch(setLoadingState(true, "Cargando Tienda"));
      const data = await fetchDocument(
        collection,
        encodeURIComponent(id),
        paramsHash && JSON.parse(paramsHash)
      );
      // dispatch(setLoadingState(false));
      if (data) {
        setDocument(data);
      }
      setState("ready");
    } catch (error) {
      console.error(error);
      // dispatch(setLoadingState(false));
      setState("error");
    }
  }, [id, collection, paramsHash, setDocument, setState]);

  useEffect(() => {
    fetchItem();
  }, [fetchItem]);

  return [document, state];
}
