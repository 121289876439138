import { useEffect } from 'react';
import useUser from './useUser';
export function useAuthorizedRequest(cb: any, triggers: any[] = []) {
  const [user] = useUser();
  const callback = () => {
    if (user) {
      cb(user);
    }
  };
  useEffect(callback, [...triggers, user && user.uid]);
}
