import React from 'react';
import { IonHeader, IonButtons, IonToolbar, IonTitle, IonButton, IonIcon, IonLabel } from '@ionic/react';
import { cartOutline, locationOutline, search, arrowBack } from 'ionicons/icons';
import useShoppingCart from '../services/hooks/useShoppingCart';
import './Header.css';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSearchBox, saveCart } from '../redux/cartReducer';
import { Textfit } from 'react-textfit';
import { useHistory } from 'react-router';
import { getDomainPath } from '../Main/customDomain';

export default function Header({ openShopSelection, noBack = false, onClose, noSearch = false, backToShop }: any) {
  const store = useSelector(({ cart }: any) => cart.supplierStore);
  const shop = useSelector(({ cart }: any) => cart.shop);
  const history = useHistory();

  const openCart = () => {
    const { key } = getDomainPath(store.shortName);
    dispatch(saveCart(history, `${key}store`));
  };

  const dispatch = useDispatch();
  const showSearchBox = useSelector(({ cart }: any) => cart.showSearchBox);
  const filters = useSelector(({ cart }: any) => cart.filters);
  const selectedCategory = useSelector(({ cart }: any) => cart.selectedCategory);
  const [shoppingCart, _, meta] = useShoppingCart(store && store.id, shop && shop.id);
  const { totalAmount, totalQty } = meta || {};
  const openSearchBox = () => {
    dispatch(setShowSearchBox(!showSearchBox));
  };
  const bannerUrl = `https://res.cloudinary.com/erguxabasto/image/upload/${'w_750,q_auto,f_auto'}/${store &&
    store.bannerUrl}`;

  return (
    <IonHeader>
      <IonToolbar className="elevated-toolbar-image no-padding">
        {backToShop ? (
          <IonButtons slot="start">
            <IonButton onClick={backToShop} color="primary">
              <IonIcon icon={arrowBack} />
            </IonButton>
          </IonButtons>
        ) : null}

        <IonTitle>
          <div className="store-title">
            <img alt="logo" src={store && store.imageUrl} />
            <Textfit mode="single" max={25}>
              {store && store.name}
            </Textfit>
          </div>
        </IonTitle>
        <IonButtons onClick={openCart} className="cart-button-container" slot="end">
          <IonButton color="primary">
            <IonIcon icon={cartOutline} />
          </IonButton>
          <IonLabel key={totalQty} className="cart-total animated bounce">
            {totalQty}
          </IonLabel>
        </IonButtons>
        <div className={`elevated-toolbar-container ion-padding-start ion-padding-end`}>
          <div className="search-terms-container">
            {filters && filters.search && (
              <IonLabel>
                {'buscar "'}
                {filters.search}
                {'"'}
              </IonLabel>
            )}

            {selectedCategory && selectedCategory.name && (
              <IonLabel>
                {' en "'}
                {selectedCategory.name}
                {'"'}
              </IonLabel>
            )}
          </div>
          <div className="search-button-container">
            <IonButtons>
              {!noSearch ? (
                <IonButton onClick={openSearchBox} color="primary">
                  <IonIcon icon={search} />
                </IonButton>
              ) : null}
            </IonButtons>
          </div>
        </div>
      </IonToolbar>
    </IonHeader>
  );
}
