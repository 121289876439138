import React, { useRef, useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import L from 'leaflet';

export const composeToolTip = (zone: any) => {
  const PICKING_MAP: any = {
    free: 'Envío gratuito',
    freeIf: 'Envío gratuito arriba de',
    cost: 'Costo de envío'
  };
  const price =
    zone.type === 'free'
      ? ''
      : zone.type === 'freeIf'
      ? `Bs. ${zone.min && parseFloat(zone.min).toFixed(2)}`
      : `Bs. ${zone.price && parseFloat(zone.price).toFixed(2)}`;
  return `${PICKING_MAP[zone.type]} ${price}`;
};
export default function PickingZoneDrawing({ zones, onClick }: any) {
  const fgRef = useRef<any>(null);
  const [loaded, setLoaded] = useState<boolean>(false);

  const _onFeatureGroupReady = (reactFGref: any) => {
    // populate the leaflet FeatureGroup with the geoJson layers

    if (zones && zones.length && reactFGref && !loaded) {
      zones.forEach((zone: any) => {
        const geoJSON = zone.features;
        let leafletGeoJSON = new L.GeoJSON(geoJSON);
        let leafletFG = reactFGref.leafletElement;
        leafletGeoJSON.eachLayer(layer => {
          leafletFG.addLayer(layer.bindTooltip(composeToolTip(zone)));
        });
        setLoaded(true);
      });
    }
    fgRef.current = reactFGref;
  };

  return (
    <FeatureGroup
      ref={reactFGref => {
        _onFeatureGroupReady(reactFGref);
      }}
    ></FeatureGroup>
  );
}
