import React from 'react';
import { IonFooter, IonToolbar } from '@ionic/react';
import './Header.css';

export default function Footer({ children, hideIfBig }: any) {
  return (
    <IonFooter className={`ion-no-border ${hideIfBig ? 'hidden' : ''}`}>
      <IonToolbar className="elevated-footer ion-footer-start ion-footer-end">{children}</IonToolbar>
    </IonFooter>
  );
}
