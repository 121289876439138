import * as firebase from 'firebase/app';
import 'firebase/auth';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, setLoadingState } from '../../redux/cartReducer';

export default function useUser() {
  let [authRegistered, setAuthRegistered] = useState<any>(false);
  const dispatch = useDispatch();
  const user = useSelector(({ cart }: any) => cart.user);
  const [requestState, setRequestState] = useState<any>('none');
  useEffect(() => {
    let unsubscribe: any;
    if (!authRegistered) {
      unsubscribe = firebase.auth().onAuthStateChanged(async function(user) {
        setRequestState('fetching');
        if (user) {
          dispatch(setUser({ ...user }));
          setRequestState('done');
        } else {
          setRequestState('done');
          return;
        }
      });
    }
    setAuthRegistered(true);
    return () => {
      setAuthRegistered(false);
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);
  return [user || null, requestState];
}
