import React from "react";
import { IonItem, IonLabel, IonButton } from "@ionic/react";
import { flattenProducts } from "./SnapshotProducts";

export function flattenCategory(category: any) {
  return [
    { type: "category", id: `category${category.id}`, data: category },
    ...flattenProducts(category.products || [])
    // { type: 'footer', id: `footer${category.id}`, data: category }
  ];
}

export function CategoryHeaderWidget({ data: category, style }: any) {
  return (
    <div style={style}>
      <div className="listing-category-header">
        <IonLabel slot="start">{category.name}</IonLabel>
        {category.hasMore ? (
          <IonButton fill="clear" slot="end">
            Ver más
          </IonButton>
        ) : null}
      </div>
    </div>
  );
}

export function CategoryFooterWidget({ data: category, style }: any) {
  if (!category.hasMore) {
    return null;
  }
  return (
    <div style={style}>
      <div className="listing-category-footer">
        <IonButton fill="clear">Ver todo</IonButton>
      </div>
    </div>
  );
}
