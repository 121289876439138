import React, { useRef } from 'react';
import { IonItem, IonToggle, IonLabel, IonSpinner, IonButton } from '@ionic/react';
import WarnIcon from './WarnIcon';
import './ElevatedInput.css';

const ElevatedToggle = ({ type, onChange, onBlur, onRemove, value, label, id, meta, loading, ...params }: any) => {
  let inputRef = useRef<any>();
  const error = meta && meta.touched && meta.error;
  return (
    <div className="elevated-box">
      <div className="elevated-container">
        <IonItem className={`elevated-item ${error ? 'ion-invalid' : ''}`}>
          <IonToggle
            {...params}
            id={id}
            name={id}
            onIonBlur={onBlur}
            className="elevated-input"
            ref={inputRef}
            onIonChange={onChange}
            checked={!!value}
            type={type}
          />
          <IonLabel className="elevated-label" color="secondary" slot="end">
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <small>{label}</small>
              {error && !loading ? <WarnIcon /> : null}
            </div>
          </IonLabel>
        </IonItem>

        {error || onRemove ? (
          <div className="elevated-error">
            {error}
            {onRemove ? (
              <IonLabel color="warning" onClick={onRemove}>
                Eliminar
              </IonLabel>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ElevatedToggle;
