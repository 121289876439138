export function getIncompleteOrders({ items }) {
  debugger;
  if (!items) {
    return;
  }
  const incompleteProducts = Object.values(items).filter(item => {
    debugger;
    const inCart = item;
    if (!inCart) {
      return false;
    }
    const options = item.options;
    const selections = inCart && inCart.selections;
    const selectionLength = selections && Object.keys(selections).length;
    const missing = inCart && options && selectionLength !== inCart.qty ? true : false;
    return missing;
  });
  return incompleteProducts;
}
