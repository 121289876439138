import React, { useImperativeHandle, forwardRef, useRef, useEffect } from 'react';
import { Formik, Form } from 'formik';

const FormTemplate = (
  { children, initialValues, validationSchema, modelInstance, onSubmit, disableReinitialize }: any,
  ref: any
) => {
  let formikForm = useRef<any>(null);

  const submitForm = () => {
    if (formikForm && formikForm.current) {
      formikForm.current.validateForm();
      formikForm.current.submitForm();
    }
  };

  const isValid = () => {
    if (formikForm && formikForm.current) {
      return formikForm.current.isValid;
    }
    return false;
  };

  const getForm = () => {
    if (formikForm && formikForm.current) {
      return formikForm.current;
    }
    return null;
  };

  useEffect(() => {
    if (formikForm && formikForm.current) {
      formikForm.current.setValues({
        ...modelInstance
      });
    }
  }, [modelInstance]);

  useImperativeHandle(ref, () => {
    return { submitForm, isValid, getForm };
  });

  return (
    <Formik
      enableReinitialize={!disableReinitialize ? true : false}
      innerRef={ref => (formikForm.current = ref)}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="ion-padding ion-text-center">{children}</div>
      </Form>
    </Formik>
  );
};

export default forwardRef(FormTemplate);
