import axios from 'axios';
import './auth';

export function sendOrderToStore(storeId: string, shopId: number, cart: any, picking: any, token: any, uid: any) {
  return axios
    .post(`/place_order/${storeId}/${shopId}`, {
      uid,
      cart,
      picking,
      token
    })
    .then(res => {
      return res.data;
    });
}

export async function getOrder(storeId: string, orderNumber: string, phone: any) {
  // const res = await axios.get(`/document/${collection}/${id}`, { params: params });
  // return res.data;
}
