export const firebaseConfig = {
  apiKey: 'AIzaSyBSkhkw8LPwc5LxNZX0ngZ4MJ0Wt1saMSs',
  authDomain: 'erguxquicklist.firebaseapp.com',
  databaseURL: 'https://erguxquicklist.firebaseio.com',
  projectId: 'erguxquicklist',
  storageBucket: 'erguxquicklist.appspot.com',
  messagingSenderId: '307478988938',
  appId: '1:307478988938:web:cd36c8cc813a77f78d4573',
  measurementId: 'G-VYK9LN4TMP'
};
