import React, { useEffect } from 'react';
import { IonLabel, IonItem, IonText } from '@ionic/react';
import { composeToolTip } from '../../../components/PickingZoneDrawing';
import { useField } from 'formik';

function DeliveryPrice({ zone, id, type = 'select' }: any) {
  const [{ value, checked }, meta, { setValue, setTouched }] = useField({
    id,
    name: id,
    type: type
  });

  useEffect(() => {
    setValue(zone);
  }, [(zone && zone.id) || null]);

  if (!zone) {
    return null;
  }
  return (
    <IonItem>
      <IonText color="warning">{composeToolTip(zone)}</IonText>
    </IonItem>
  );
}

export default React.memo(DeliveryPrice);
