import React, { useState } from 'react';
import { IonSearchbar, IonHeader, IonPage, IonToolbar, IonContent, IonSpinner, IonLabel } from '@ionic/react';
import { useCollection } from '../services/hooks/useCollection';
import SupplierList from './SuppliersList';

const MarketPlace: React.FC = ({ history }: any) => {
  const offset = 0;
  const limitStep = 50;
  const [searchTerm, setSearchTerm] = useState<any>('');
  const [limit, setLimit] = useState(limitStep);
  const [suppliers, suppliersState] = useCollection('Suppliers', {
    search: { name: searchTerm },
    // attributes: ['id', 'localId', 'name', 'logo', 'description'],
    pagination: { offset, limit }
  });

  const goToSupplierStore = (supplier: any) => {
    history.push(`/${supplier.id}`);
  };

  const loadMoreSuppliers = (lastIndex: number) => {
    if (lastIndex + 1 >= limit) {
      setLimit(limit + limitStep);
    }
  };

  return (
    <IonPage>
      {/* <IonHeader>
        <IonToolbar className="elevated-toolbar-light">
          <div className="ion-padding">
            <IonSearchbar value={searchTerm} onIonChange={e => setSearchTerm(e.detail.value)}></IonSearchbar>
          </div>
        </IonToolbar>
      </IonHeader> */}
      <IonContent>
        <div className="home-banner-container">
          <img
            className="home-banner"
            src={`https://res.cloudinary.com/erguxabasto/image/upload/f_auto,q_auto:good/v1587403038/public/publish_hq7dmi.png`}
          ></img>
        </div>
        {/* <SupplierList
          maxWidth={500}
          goToDetail={goToSupplierStore}
          data={suppliers}
          loadMoreData={loadMoreSuppliers}
        ></SupplierList>
        {suppliersState === 'fetching' ? (
          <div
            slot="fixed"
            style={{ bottom: 0, left: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <IonLabel>Cargando...</IonLabel>
            <IonSpinner></IonSpinner>
          </div>
        ) : null} */}
      </IonContent>
    </IonPage>
  );
};

export default MarketPlace;
