import React from 'react';
import ProductSelections from './ProductSelections';
import { IonLabel, IonList, IonListHeader, IonTitle } from '@ionic/react';
import { useDispatch } from 'react-redux';
import { setProductOptionValue, setProductComments } from '../../redux/cartReducer';
import ProductDetails from './ProductDetails';

export default function ProductOptions({ product, inCart }: any) {
  const options = product && product.options;
  const dispatch = useDispatch();

  const setComments = (val: string) => {
    dispatch(setProductComments(inCart.id, val));
  };

  if (!inCart) {
    return null;
  }

  if (!options && inCart) {
    return <ProductDetails value={inCart.comments} onChange={setComments}></ProductDetails>;
  }
  const qty = Math.floor(inCart.qty);

  let base = Array.from({ length: qty }, (x, i) => i);
  const selections = inCart.selections || {};
  let platesMap = base.reduce((m, i) => {
    return { ...m, [i]: selections[i] || { id: i } };
  }, {});

  const setSelection = (itemId: any, optionId: any, option: any, val: any) => {
    dispatch(setProductOptionValue(inCart.id, itemId, optionId, { value: val, option }));
  };

  return (
    <div className="options-list">
      <ProductDetails value={inCart.comments} onChange={setComments}></ProductDetails>
      <IonLabel className="option-title" color="medium">
        Selecciona tus opciones
      </IonLabel>
      {Object.values(platesMap).map((selection: any) => (
        <IonList key={selection.id}>
          <IonListHeader>
            <IonLabel>Item {selection.id + 1}</IonLabel>
          </IonListHeader>
          <ProductSelections
            setSelection={(optionId: any, option: any, value: any) =>
              setSelection(selection.id, optionId, option, value)
            }
            key={selection.id}
            options={options}
            selection={selection}
          ></ProductSelections>
        </IonList>
      ))}
    </div>
  );
}
