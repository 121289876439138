import React from 'react';
import ElevatedTextarea from '../../../../components/form/ElevatedTextarea';

export default function ProductDetails({ onChange, value }: any) {
  return (
    <div style={{ padding: '0 2rem' }}>
      <ElevatedTextarea
        onChange={(e: any) => onChange(e.detail.value)}
        value={value}
        label="¿Detallar algo adicional?"
      ></ElevatedTextarea>
    </div>
  );
}
